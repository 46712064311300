import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Typography } from "@mui/material";
import Parser from 'html-react-parser';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from "@material-ui/core/styles";
import $ from 'jquery';
import Draggable from 'react-draggable';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Thumbnail from "./Thumbnail";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { SuspenseImg } from "../helpers/SuspenseImage";




export const Account = (props) => {
  var { socialmedia, link, logo, description } = props.data;
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);

  function removePngExtension(filename) {
    if (filename.endsWith('.png')) {
      return filename.slice(0, -4);
    }
    return filename;
  }

  logo = removePngExtension(logo) + "-new.png"

  useEffect(() => {
    setTheme(t);
  }, [t])

  return (
    <>
      <Card sx={{ maxWidth: 350, marginBottom: 3 }}>
        <CardMedia
          component="img"
          alt="default avatar"
          height="350"
          image={logo}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <a target="_blank" style={{ color: "#0D1F2D", textDecoration: "none" }} href={link}>{socialmedia === "Twitter" ? "X / Twitter" : socialmedia}</a>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <p style={{ color: '#000' }} >{description}</p>
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" size="small" onClick={() => { window.open(link, "_blank"); }}>Go to Profil</Button>
        </CardActions>
      </Card>
    </>
  );
}

export const SocialMedia = (props) => {
  const { id, name, description, website } = props.data;
  return (
    <>
      <h3><a target="_blank" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a></h3>
      <div class="overflow-auto" style={{ maxHeight: 280 }}>
        <small style={{ display: "none" }}>{id}</small>
        <p class="marginItem" style={{ marginRight: "100px" }}>{description}</p>
      </div>
    </>
  );
}

export const SocialMediaNews = ({ index, data }) => {
  const { id, name, description, website, news, timeago } = data;
  const identifier = "window_" + index;
  const identifierButton = "button_" + index;
  const [layout, setLayout] = useState(1);
  const qs = useSelector(state => state.quoteswitch);
  const [quoteswitch, setQuoteSwitch] = useState(qs);
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);
  const [activeScroll, setActiveScroll] = useState(false);

  const handleLayoutChange = (e) => {
    e.preventDefault();
    if (layout === 1) {
      setLayout(2);
    } else if (layout === 2) {
      setLayout(1);
    }
  }

  useEffect(() => {
    setLayout(layout);
  }, [layout])

  useEffect(() => {
    localStorage.setItem("quote", qs)
    setQuoteSwitch(qs);
  }, [qs]);

  useEffect(() => {
    setTheme(t);
  }, [t])

  const makeScrollable = (e) => {
    setLayout(1);
    const index = e.target.id.split("_")[1];
    const window = "window_" + index;
    if (!activeScroll /*&& document.getElementById(window).style.overflow === "hidden"*/) {
      // document.getElementById(window).style.overflowY = "auto";
      // document.getElementById(e.target.id).innerText = "See Less";
      setActiveScroll(true);
    } else {
      // document.getElementById(window).style.overflowY = "hidden";
      // document.getElementById(e.target.id).innerText = "See More";
      setActiveScroll(false);
      $(window).scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  const displayImage = () => setTimeout(() => {
    setLayout(1);
  }, 10000);

  useEffect(() => {
    var identifier = "window_" + index;
    const interval = setInterval(() => {
      if (getRandomNumber(1, 100) >= 93 && getRandomNumber(1, 100) <= 100 && document.getElementById(identifier + "t").style.overflowY !== "auto") {
        setLayout(3);
        displayImage();
      }
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  if (layout === 1) {
    return (
      <>
        <div id={identifier} style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderRadius: 20, maxWidth: 350, overflowX: "hidden", overflowY: "hidden", paddingBottom: 10 }} >
          <h3 style={{ maxWidth: 350, borderTopLeftRadius: "20px 20px", margin: 0 }} class="p-3 bg-primary bg-gradient text-white draggable"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon className="notDraggable" onClick={e => handleLayoutChange(e)} style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
          <div id={identifier + "t"} style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxHeight: 500, maxWidth: 350, overflowX: "hidden", overflowY: activeScroll ? "auto" : "hidden", paddingLeft: 8 }} class="itemHeightMobil">
            <small style={{ display: "none" }}>{id}</small>
            {news.length !== 0 ?
              news.map((d) => (
                <p class="marginItem" style={{ marginBottom: 5, marginTop: 5, marginRight: 8 }}><span class="bg-primary bg-gradient text-white" style={{ padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{"  "}<ArticleLink data={d} quoteswitch={quoteswitch} /></p>
              )) :
              <p class="marginItem" style={{ marginBottom: "0" }}>Updating...</p>
            }
          </div>
          <div style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxWidth: 350, borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px", padding: 10 }}>
            {news.length > 2 ?
              <Button id={identifierButton + "t"} style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }} onClick={makeScrollable} variant="contained">
                {activeScroll ? "See Less" : "See More"}
              </Button> :
              <></>
            }
          </div>
        </div>
      </>
    );
  } else if (layout === 2) {
    return (
      <>
        <h3 style={{ maxHeight: 250, maxWidth: 350, borderRadius: 20 }} class="p-3 mb-2 bg-primary bg-gradient text-white"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <div style={{ maxHeight: 250, maxWidth: 350, overflowX: "hidden", overflowY: "auto", marginLeft: 8 }}>
          <small style={{ display: "none" }}>{id}</small>
          <p class="marginItem">{description}</p>
        </div>
      </>
    );
  } else if (layout === 3) {
    return (
      <>
        <h3 style={{ maxWidth: 350, borderRadius: 20 }} class="p-3 mb-2 bg-primary bg-gradient text-white"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <div id={identifier} style={{ backgroundColor: theme === 'dark' ? "#0D1F2D" : "#F7F9F9", borderRadius: 8, maxHeight: 250, maxWidth: 345, overflowX: "hidden", overflowY: "hidden", marginLeft: 5 }} class="itemHeightMobil">
          <small style={{ display: "none" }}>{id}</small>
          <Image name={name} setLayout={setLayout} theme={theme} />
        </div>
        {news.length > 2 ?
          <Button id={identifierButton} style={{ marginLeft: 6, marginTop: 10, borderRadius: 20 }} onClick={makeScrollable} variant="contained">
            See More
          </Button> :
          <></>
        }
      </>
    );
  }

}

export const SocialMediaNewsPreview = ({ index, data }) => {
  const { id, name, description, website, news, timeago, linkspreview } = data;
  const identifier = "window_" + index;
  const identifierButton = "button_" + index;
  const [layout, setLayout] = useState(1);
  const qs = useSelector(state => state.quoteswitch);
  const [quoteswitch, setQuoteSwitch] = useState(qs);
  const pw = useSelector(state => state.previewswitch);
  const [previewswitch, setPreviewSwitch] = useState(pw);
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);
  const [activeScroll, setActiveScroll] = useState(false);

  const s = useSelector(state => state.socialmedia.socialmediaList);
  const [socialmediaList, setSocialmedialList] = useState(s);
  const ses = useSelector(state => state.socialmediaES.socialmediaListES);
  const [socialmediaListES, setSocialmedialListES] = useState(ses);
  const sde = useSelector(state => state.socialmediaDE.socialmediaListDE);
  const [socialmediaListDE, setSocialmedialListDE] = useState(sde);
  const sfr = useSelector(state => state.socialmediaFR.socialmediaListFR);
  const [socialmediaListFR, setSocialmedialListFR] = useState(sfr);
  const spt = useSelector(state => state.socialmediaPT.socialmediaListPT);
  const [socialmediaListPT, setSocialmedialListPT] = useState(spt);
  const shi = useSelector(state => state.socialmediaHI.socialmediaListHI);
  const [socialmediaListHI, setSocialmedialListHI] = useState(shi);
  const sindo = useSelector(state => state.socialmediaINDO.socialmediaListINDO);
  const [socialmediaListINDO, setSocialmedialListINDO] = useState(sindo);
  const sru = useSelector(state => state.socialmediaRU.socialmediaListRU);
  const [socialmediaListRU, setSocialmedialListRU] = useState(sru);
  const sch = useSelector(state => state.socialmediaCH.socialmediaListCH);
  const [socialmediaListCH, setSocialmedialListCH] = useState(sch);
  const sjp = useSelector(state => state.socialmediaJP.socialmediaListJP);
  const [socialmediaListJP, setSocialmedialListJP] = useState(sjp);
  const sar = useSelector(state => state.socialmediaAR.socialmediaListAR);
  const [socialmediaListAR, setSocialmedialListAR] = useState(sar);
  const sit = useSelector(state => state.socialmediaIT.socialmediaListIT);
  const [socialmediaListIT, setSocialmedialListIT] = useState(sit);
  const str = useSelector(state => state.socialmediaTR.socialmediaListTR);
  const [socialmediaListTR, setSocialmedialListTR] = useState(str);
  const snl = useSelector(state => state.socialmediaNL.socialmediaListNL);
  const [socialmediaListNL, setSocialmedialListNL] = useState(snl);
  const sgr = useSelector(state => state.socialmediaGR.socialmediaListGR);
  const [socialmediaListGR, setSocialmedialListGR] = useState(sgr);


  const handleLayoutChange = (e) => {
    e.preventDefault();
    if (layout === 1) {
      setLayout(3);
    } else if (layout === 3) {
      setLayout(1);
    }
  }

  useEffect(() => {
    setLayout(layout);
  }, [layout])

  useEffect(() => {
    localStorage.setItem("quote", qs)
    setQuoteSwitch(qs);
  }, [qs]);

  useEffect(() => {
    setPreviewSwitch(pw);
  }, [pw]);

  useEffect(() => {
    setTheme(t);
  }, [t])

  const makeScrollable = (e) => {
    if (layout === 1 || layout === 4) {
      setLayout(2);
    } else if (layout === 2) {
      setLayout(1);
    }
  }

  const makeScrollableForText = (e) => {
    setLayout(1);
    const index = e.target.id.split("_")[1];
    const window = "window_" + index;
    if (!activeScroll /*&& document.getElementById(window).style.overflow === "hidden"*/) {
      // document.getElementById(window).style.overflowY = "auto";
      setActiveScroll(true);
      // document.getElementById(e.target.id).innerText = "See Less";
    } else {
      // document.getElementById(window).style.overflowY = "hidden";
      setActiveScroll(false);
      // document.getElementById(e.target.id).innerText = "See More";
      $(window).scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  /* const displayImage = () => setTimeout(() => {
      setLayout(1);
    }, 10000);
  
    useEffect(() => {
      var identifier = "window_" + index;
      const interval = setInterval(() => {
      var rNummer = getRandomNumber(1, 100);
      if (rNummer >= 95 && rNummer <= 100 && document.getElementById(identifier + "t").style.overflowY !== "auto") {
        setLayout(3);
        displayImage();
      }
    }, 5000);
  
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []) 
  */

  if (layout === 1) {
    // var autoplayTime = window.innerWidth >= 960 ? 15000 : 60000;
    var autoplayTime = 5000;

    return (
      <div id={identifier} style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderRadius: 20, maxWidth: 350, overflowX: "hidden", overflowY: "hidden", paddingBottom: 10 }} >
        <h3 style={{ maxWidth: 350, borderTopLeftRadius: "20px 20px", margin: 0 }} class="p-3 bg-primary bg-gradient text-white draggable"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} className="notDraggable" style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <Swiper
          centeredSlides={true}
          loop={true}
          autoHeight={true}
          effect={"fade"}
          autoplay={{
            delay: autoplayTime,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={linkspreview && linkspreview.length !== 0 ? true : false}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          className="mySwiper"
        >
          {linkspreview && linkspreview.length !== 0 ?
            linkspreview.map((d, idx) => {

              var imgId = "preview_" + name + "_" + idx;

              const PreviewImage = () => {
                const [loaded, setLoaded] = useState(false);
                const showImage = () => {
                  setLoaded(true)
                }
                const [error, setError] = useState(false);
                const hasError = () => {
                  setError(true)
                }
                return (
                  <Suspense>
                    <div key={idx}>
                      {theme === "dark" && <SuspenseImg alt="" src={error ? DefaultImage(name, theme) : d.image} onError={(e) => { e.preventDefault(); hasError(); }} />}
                      {theme === "light" && <SuspenseImg alt="" src={error ? DefaultImage(name, theme) : d.image} onError={(e) => { e.preventDefault(); hasError(); }} />}
                    </div>
                  </Suspense>
                );
              }

              return (
                <SwiperSlide>
                  <div>
                    <PreviewImage />
                    <p class="p-3 bg-primary bg-gradient text-white" style={{ margin: 0, width: "100%", background: "#166fe5", textAlign: "left", color: '#fff', opacity: 1 }}><span class="bg-primary bg-gradient text-white" style={{ margin: 0, padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{" "}<ArticleLink data={d} quoteswitch={quoteswitch} previewswitch={previewswitch} /></p>
                  </div>
                </SwiperSlide>);
            }) :
            news && news.length === 0 ?
              <>
                <Image name={name} theme={theme} />
                <p class="p-3 bg-primary bg-gradient text-white" style={{ marginBottom: "0", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }}>Updating...</p>
              </>
              :
              <>
                <div id={identifier + "t"} style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxHeight: 500, maxWidth: 350, overflowX: "hidden", overflowY: activeScroll ? "auto" : "hidden", paddingLeft: 8 }} class="itemHeightMobil">
                  <small style={{ display: "none" }}>{id}</small>
                  {news.length !== 0 ?
                    news.map((d) => (
                      <p class="marginItem" style={{ marginBottom: 5, marginTop: 5, marginRight: 8 }}><span class="bg-primary bg-gradient text-white" style={{ padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{"  "}<ArticleLink data={d} quoteswitch={quoteswitch} /></p>
                    )) :
                    <p class="marginItem" style={{ marginBottom: "0" }}>Updating...</p>
                  }
                </div>
                <div style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxWidth: 350, borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px", padding: 10 }}>
                  {news.length > 2 ?
                    <Button id={identifierButton + "t"} style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }} onClick={makeScrollableForText} variant="contained">
                      {activeScroll ? "See Less" : "See More"}
                    </Button> :
                    <></>
                  }
                </div>
              </>
          }
        </Swiper>
        {linkspreview && linkspreview.length !== 0 ?
          <Button id={identifierButton} style={{ marginLeft: 6, marginTop: 10, borderRadius: 20 }} onClick={makeScrollable} variant="contained">
            See More
          </Button> :
          <></>
        }
      </div>
    );
  }
  else if (layout === 2) {
    return (
      <>
        <h3 style={{ maxWidth: 350, borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0 }} class="p-3 bg-primary bg-gradient text-white draggable"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} className="notDraggable" style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <div id={identifier} style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxHeight: 500, maxWidth: 350, overflowX: "hidden", overflowY: "auto", paddingLeft: 8 }} class="itemHeightMobil">
          <small style={{ display: "none" }}>{id}</small>
          {news.length !== 0 ?
            news.map((d) => (
              <p class="marginItem" style={{ marginBottom: 5, marginTop: 5, marginRight: 8 }}><span class="bg-primary bg-gradient text-white" style={{ padding: 2, borderRadius: 5 }} ><b>{d.timeago}</b></span>{"  "}<ArticleLink data={d} quoteswitch={quoteswitch} /></p>
            )) :
            <p class="marginItem" style={{ marginBottom: "0" }}>Updating...</p>
          }
        </div>
        <div style={{ backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", maxWidth: 350, borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px", padding: 10 }}>
          <Button id={identifierButton} style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }} onClick={makeScrollable} variant="contained">
            See Less
          </Button>
        </div>
      </>
    );
  } else if (layout === 3) {
    return (
      <>
        <h3 style={{ maxHeight: 250, maxWidth: 350, borderRadius: 20 }} class="p-3 mb-2 bg-primary bg-gradient text-white"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <div style={{ maxHeight: 250, maxWidth: 350, overflowX: "hidden", overflowY: "auto", marginLeft: 8 }}>
          <small style={{ display: "none" }}>{id}</small>
          <p class="marginItem">{description}</p>
        </div>
      </>
    );
  } else if (layout === 4) {
    return (
      <>
        <h3 style={{ maxWidth: 350, borderRadius: 20 }} class="p-3 mb-2 bg-primary bg-gradient text-white"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={website}>{name}</a> <Logo name={name} /><InfoIcon onClick={e => handleLayoutChange(e)} style={{ float: "right", marginTop: 5, cursor: "pointer" }} /></h3>
        <div id={identifier} style={{ backgroundColor: theme === 'dark' ? "#0D1F2D" : "#F7F9F9", borderRadius: 8, maxHeight: 250, maxWidth: 345, overflowX: "hidden", overflowY: "hidden", marginLeft: 5 }} class="itemHeightMobil">
          <small style={{ display: "none" }}>{id}</small>
          <Image name={name} setLayout={setLayout} theme={theme} />
        </div>
      </>
    );
  }

}

export const setDefaultImage = (imgId, socialmedia, theme) => {
  var defaultImage;
  var windowSize = window.innerWidth;
  if (windowSize <= 320) {
    defaultImage = "/" + socialmedia.toString().toLowerCase() + "-image" + "-" + theme + "320" + ".png";
  } else {
    defaultImage = "/" + socialmedia.toString().toLowerCase() + "-image" + "-" + theme + ".png";
  }
  document.getElementById(imgId).src = defaultImage;
}

export const DefaultImage = (socialmedia, theme) => {
  var defaultImage;
  var windowSize = window.innerWidth;
  if (windowSize <= 320) {
    defaultImage = "/" + socialmedia.toString().toLowerCase() + "-image" + "-" + theme + "320" + ".png";
  } else {
    defaultImage = "/" + socialmedia.toString().toLowerCase() + "-image" + "-" + theme + ".png";
  }
  return defaultImage;
}

export const Breach = (props) => {
  const breach = props.data;

  return (
    <>
      <h3 style={{ maxHeight: 250, maxWidth: 350, borderRadius: 20 }} class="p-3 mb-2 bg-primary bg-gradient text-white"><a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={breach.domain}>{breach.name}</a></h3>
      <div style={{ maxHeight: 250, maxWidth: 350, overflowX: "hidden", overflowY: "auto", marginLeft: 8 }}>
        <p class="marginItem" style={{ marginBottom: "0" }}>{Parser(breach.description)}</p>
      </div>
    </>
  );
}

const Logo = ({ name }) => {
  switch (name) {
    case "Twitter":
      return <XIcon />;
    case "Facebook":
      return <FacebookIcon />;
    case "Instagram":
      return <InstagramIcon />;
    case "YouTube":
      return <YouTubeIcon />;
    case "WhatsApp":
      return <WhatsAppIcon />;
    case "Github":
      return <GitHubIcon />;
    case "Reddit":
      return <RedditIcon />;
    case "Linkedin":
      return <LinkedInIcon />;
    case "Telegram":
      return <TelegramIcon />;
    case "Xing":
      return <img alt="xing-logo" src="/xing-logo.png" />;
    case "Wechat":
      return <img alt="wechat-logo" src="/wechat-logo.png" />;
    case "Vkontakte":
      return <img alt="vk-logo" src="/vk-logo.png" />;
    case "Pinterest":
      return <PinterestIcon />;
    case "Discord":
      return <img alt="discord-logo" src="/discord-logo.png" />;
    case "Tinder":
      return <img alt="tinder-logo" src="/tinder-logo.png" />;
    case "Signal":
      return <img alt="signal-logo" src="/signal-logo.png" />;
    case "Snapchat":
      return <img alt="snapchat-logo" src="/snapchat-logo.png" />;
    case "TikTok":
      return <img alt="tiktok-logo" src="/tiktok-logo.png" />;
    case "Twitch":
      return <img alt="twitch-logo" src="/twitch-logo.png" />;
    case "Patreon":
      return <img alt="patreon-logo" src="/patreon-logo.png" />;
    case "Onlyfans":
      return <><img alt="onlyfans-logo" src="/onlyfans-logo.png" /> <img alt="18-logo" src="/18.png" /></>;
    default:
      return <></>;
  }
}

const Image = ({ name, setLayout, theme }) => {
  var windowSize = window.innerWidth;
  var imageLocation;
  var tmpName;
  if (windowSize <= 320) {
    if (name === "Twitter")
      tmpName = "X-Twitter";
    else
      tmpName = name;
    imageLocation = "/" + tmpName.toLowerCase() + "-image" + "-" + theme + "320" + ".png";
  } else {
    if (name === "Twitter")
      tmpName = "X-Twitter";
    else
      tmpName = name;
    imageLocation = "/" + tmpName.toLowerCase() + "-image" + "-" + theme + ".png";
  }

  switch (name) {
    case "Twitter":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="twitter-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="twitter-image-dark" src={imageLocation} />
    case "Facebook":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="facebook-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="facebook-image-dark" src={imageLocation} />
    case "Instagram":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="instagram-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="instagram-image-dark" src={imageLocation} />
    case "YouTube":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="youtube-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} alt="youtube-image-dark" src={imageLocation} />
    case "WhatsApp":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="whatsapp-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} alt="whatsapp-image-dark" src={imageLocation} />
    case "Github":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="github-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="github-image-dark" src={imageLocation} />
    case "Reddit":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="reddit-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="reddit-image-dark" src={imageLocation} />
    case "Linkedin":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="linkedin-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="linkedin-image-dark" src={imageLocation} />
    case "Telegram":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="telegram-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="telegram-image-dark" src={imageLocation} />
    case "Xing":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="xing-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="xing-image-dark" src={imageLocation} />
    case "Wechat":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="wechat-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="wechat-image-dark" src={imageLocation} />
    case "Vkontakte":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="vk-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="vk-image-dark" src={imageLocation} />
    case "Pinterest":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="pinterest-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="pinterest-image-dark" src={imageLocation} />
    case "Discord":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="discord-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="discord-image-dark" src={imageLocation} />
    case "Tinder":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="tinder-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="tinder-image-dark" src={imageLocation} />
    case "Signal":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="signal-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="signal-image-dark" src={imageLocation} />
    case "Snapchat":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="snapchat-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="snapchat-image-dark" src={imageLocation} />
    case "TikTok":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="tiktok-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="tiktok-image-dark" src={imageLocation} />
    case "Twitch":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="twitch-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="twitch-image-dark" src={imageLocation} />
    case "Patreon":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="patreon-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="patreon-image-dark" src={imageLocation} />
    case "Onlyfans":
      return theme === "light" ? <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="onlyfans-image" src={imageLocation} />
        : <img onClick={() => { setLayout(1) }} onTouchMove={() => { setLayout(1) }} onMouseOver={() => { setLayout(1) }} alt="onlyfans-image-dark" src={imageLocation} />
    default:
      return <></>;
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'hidden !important',
    width: 350,
    '@media (min-width: 600px) and (max-width: 800px)': {
      width: 500
    },

    '@media (min-width: 800px)': {
      width: 600
    }
  },
  customizedButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    float: 'right',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const DraggableDialogForPreview = ({ open, handleCloseForPreview, url }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const changeUrl = () => {
    window.open(url)
  }

  const q = useSelector(state => state.quote.quote);
  const [quote, setQuote] = useState(q);
  var index = Math.floor(Math.random() * (quote.length + 1))
  const [loadingQuote, setLoadingQuote] = useState(false)
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);

  useEffect(() => {
    setQuote(q)
  }, [q]);

  useEffect(() => {
    setTheme(t);
  }, [t])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseForPreview}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
          <span>Keep reading</span>
        </DialogTitle>
        <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
          <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
            {/*             <div style={{ position: "fixed", zIndex: "1 !important", top : 200, left : 50 }}>
              <img src="thumbs_up_512x512.jpg" />
            </div>  */}
            {/* <ThumbnailForOneLink url={url}/> */}
            <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}><b>Help us build our <a class="hover" target="_blank" href="https://twitter.com/OneLikeApp">Twitter</a>{", "}<a target="_blank" href="https://www.reddit.com/r/OneLike/">Reddit</a>{" and "}<a target="_blank" href="https://t.me/OnelikeApp">Telegram</a> Communities !</b></p><br />
            <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}>{quote[index] ? "\"" + quote[index].text + "\"" : ""}</p>
            <p style={{ marginLeft: 15, marginBottom: 0, paddingTop: 10 }}>{quote[index] ? quote[index].author : ""}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
          <Button onClick={handleCloseForPreview} variant="contained">
            Close
          </Button>
          <Button onClick={changeUrl} variant="contained">
            Read the full Article
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ThumbnailForOneLink = ({ title, url }) => {
  var width; var height;
  if (window.innerWidth < 600) {
    width = 350; height = 600;
  } else if (window.innerWidth >= 600 && window.innerWidth < 1200) {
    width = 500; height = 800;
  } else if (window.innerWidth >= 1200) {
    width = 600; height = 900;
  }

  const checkIfLoaded = () => {
    console.log('loaded')
    // console.log(document.getElementById('iframe'))
    /*     try {
          console.log(document.getElementById('iframe').contentWindow.document)
        } catch (error) {
          $('#iframe').remove();
        } */

    // console.log(document.getElementById('iframe').document)


    // console.log(document.getElementById('iframe').contentWindow.document)
    // console.log(!($('#iframe').contentDocument))
    // console.log(!($('#iframe').contentDocument.title))
    // console.log(title !== $('#iframe').contentDocument.title)
    // if(!($('#iframe').contentDocument) || !($('#iframe').contentDocument.title) || title !== $('#iframe').contentDocument.title){
    //   console.log('Conditions1')
    //   $('#iframe').remove();
    // }
  }

  return (
    <Thumbnail
      url={url}
      width={width}
      height={height}
      iframeHeight={1920}
      iframeWidth={1080}
      interactive={false}
      onLoad={checkIfLoaded}
    />
  );
}

const ArticleLink = ({ data, quoteswitch, previewswitch }) => {
  const [open, setOpen] = useState(false);
  const t = useSelector(state => state.themeswitch);
  const [theme, setTheme] = useState(t);



  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  const handleCloseForPreview = () => {
    setOpen(false);
  }

  useEffect(() => {
    setTheme(t);
  }, [t])

  if (previewswitch) {
    if (quoteswitch) {
      return (
        <>
          <span
            className="white"
            style={{ color: '#fff', textDecoration: "underline dotted", cursor: "pointer" }}
            onClick={e => handleOpen(e)}
          >
            {data.title}
          </span>
          <DraggableDialogForPreview open={open} handleCloseForPreview={handleCloseForPreview} url={data.url} title={data.title} />
        </>
      );
    } else {
      return (
        <span
          className="white"
          style={{ color: '#fff', textDecoration: "underline dotted", cursor: "pointer" }}
          onClick={() => { window.open(data.url, "_blank"); }}
        >
          {data.title}
        </span>
      );
    }
  } else {
    if (quoteswitch) {
      return (
        <>
          <span
            className="hover"
            style={{ color: theme === 'dark' ? '#fff' : '#000', textDecoration: "underline dotted", cursor: "pointer" }}
            onClick={e => handleOpen(e)}
          >
            {data.title}
          </span>
          <DraggableDialogForPreview open={open} handleCloseForPreview={handleCloseForPreview} url={data.url} title={data.title} />
        </>
      );
    } else {
      return (
        <span
          className="hover"
          style={{ color: theme === 'dark' ? '#fff' : '#000', textDecoration: "underline dotted", cursor: "pointer" }}
          onClick={() => { window.open(data.url, "_blank"); }}
        >
          {data.title}
        </span>
      );
    }
  }


}

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

{/*                   <>
                    {theme === "dark" && <img id={imgId} loading="lazy" alt="" src={error ? DefaultImage(name, theme) : loaded ? d.image : DefaultImage(name, theme)} onLoad={(e) => { e.preventDefault(); showImage(); }} onError={(e) => { e.preventDefault(); hasError(); }} />}
                    {theme === "light" && <img id={imgId} loading="lazy" alt="" src={error ? DefaultImage(name, theme) : loaded ? d.image : DefaultImage(name, theme)} onLoad={(e) => { e.preventDefault(); showImage(); }} onError={(e) => { e.preventDefault(); hasError(); }} />}
                  </> */}