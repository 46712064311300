import { Grid, IconButton, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pageActions } from '../actions/page.actions';
import { listonespaceswitchConstants, pageConstants, publisherswitchConstants, spaceswitchConstants, switchConstants } from '../constants';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { DraggableLoadingPopup } from './SpaceItem';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { styled } from '@mui/system';
import { Spinner } from "../spinner/spinner";
import { AdsComponent } from "./AdsComponent";
import { ButtonSpinner } from "./CustomPagination";

import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
import { appreciationActions, discussionActions, infoActions, messageActions, profilActions, publisherActions, publisherswitchActions, quoteActions, socialMediaActions, spaceActions } from "../actions";




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

export const CustomPublisherPagination = ({ data, RenderComponent, title, pageLimit, dataLimit, search }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [pages] = useState(data.length % dataLimit === 0 ? data.length / dataLimit : Math.floor(data.length / dataLimit) + 1);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const p = useSelector(state => state.page);
    const [page, setPage] = useState(p);
    const [currentPage, setCurrentPage] = useState(page);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const authenticated = loggedIn && user;

    const ss = useSelector(state => state.spaceswitch);
    const lop = useSelector(state => state.listonespaceswitch);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))

    const { height, width } = useWindowDimensions();

    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);
    const publisherSwitch = useSelector(state => state.publisherswitch);
    const menuSwitch = useSelector(state => state.menuswitch);
    const lp = useSelector(state => state.publisher.loading);
    const [loadingPublisher, setLoadingPublisher] = useState(lp);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    // console.log(layout)

    // useEffect(() => {
    //     localStorage.setItem("layout", JSON.stringify(layout));
    // }, [layout]);


    useEffect(() => {
        localStorage.setItem("page", p)
        setPage(p);
    }, [p])

    function goToNextPage() {
        if (page < pages) {
            dispatch(pageActions.changePage(pageConstants.NEXT))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function goToPreviousPage() {
        if (page > 1) {
            dispatch(pageActions.changePage(pageConstants.PREVIOUS))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        // setCurrentPage(pageNumber);
        dispatch(pageActions.changePage(pageConstants.CURRENT, pageNumber))
        scrollToTop();
    }

    const getPaginatedData = () => {
        const startIndex = page * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((page - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    const scrollToTop = () => {
        setLoading(true)

        goToTop()

        interval() // For making good the transition between pages
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    const dispatchAll = () => {
        if (menuSwitch == switchConstants.PUBLISHERS_NR) {
            resetPage()
        }

        if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
        }

        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
            if (ss == spaceswitchConstants.FEED_NR) {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
            }
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(spaceActions.getSpaceList());
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(discussionActions.getDiscussionList(urlp, "", false));
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        batch(() => {
            dispatch(socialMediaActions.getSocialMediaList());
            dispatch(socialMediaActions.getSocialMediaListES());
            dispatch(socialMediaActions.getSocialMediaListDE());
            dispatch(socialMediaActions.getSocialMediaListFR());
            dispatch(socialMediaActions.getSocialMediaListPT());
            dispatch(socialMediaActions.getSocialMediaListHI());
            dispatch(socialMediaActions.getSocialMediaListINDO());
            dispatch(socialMediaActions.getSocialMediaListRU());
            dispatch(socialMediaActions.getSocialMediaListCH());
            dispatch(socialMediaActions.getSocialMediaListJP());
            dispatch(socialMediaActions.getSocialMediaListAR());
            dispatch(socialMediaActions.getSocialMediaListIT());
            dispatch(socialMediaActions.getSocialMediaListTR());
            dispatch(socialMediaActions.getSocialMediaListNL());
            dispatch(socialMediaActions.getSocialMediaListGR());
            dispatch(publisherActions.getPublisherList())
            dispatch(quoteActions.getQuotes());

            dispatch(infoActions.getInfos())
            if (authenticated) {
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            }
        })
    }

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setUser(u)
    }, [u])

    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    useEffect(() => {
        setLoadingPublisher(lp);
    }, [lp]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var rows = 0;

    return (
        <>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                    <div>

                        <Spinner show={loading} />

                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                                {title} {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                    socialLoading ||
                                    socialLoadingES ||
                                    socialLoadingDE ||
                                    socialLoadingFR ||
                                    socialLoadingPT ||
                                    socialLoadingHI ||
                                    socialLoadingINDO ||
                                    socialLoadingRU ||
                                    socialLoadingCH ||
                                    socialLoadingJP ||
                                    socialLoadingAR ||
                                    socialLoadingIT ||
                                    socialLoadingTR ||
                                    socialLoadingNL ||
                                    socialLoadingGR ||
                                    loadingMyAppreciation ||
                                    loadingMessage ||
                                    loadingInfo ||
                                    loadingQuote ||
                                    loadingPublisher
                                } />}
                            </h1> {/* light */}
                        </div>

                        <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />

                        {/* show the posts, 10 posts at a time */}
                        {getPaginatedData() && getPaginatedData().length === 0 ?
                            <>
                                {search ?
                                    <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                            <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                        </h2>
                                    </div>
                                    :
                                    <>
                                        {publisherSwitch == publisherswitchConstants.PINNED_NR ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 900, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Here you'll find your pinned News
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={dispatchAll} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later...
                                                </h2>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            : <Grid
                                container
                                spacing={4}
                                // className="marginLaptop"
                                justifyItems="center"
                            // style={{ PaddingRight: "1000px" }}
                            >
                                {getPaginatedData().map((d, idx) => (
                                    <Grid key={`${d.ifid}_${idx}`} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                        <RenderComponent key={`${d.ifid}_${idx}`} index={`${d.ifid}_${idx}`} data={d} />
                                    </Grid>
                                ))}
                            </Grid>}

                        {pages > 1 && getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={5}
                            // style={{ paddingBottom: 100, marginBottom: 70 }}
                            >
                                {page > 1 ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToPreviousPage} title="Previous">
                                        <ArrowCircleLeftIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                                {page < pages ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToNextPage} title="Next">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                            </Stack> : null}

                        {getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction={width >= 1482 ? "row" : "column"} //1200 1482
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={1}
                                style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                            >
                                {<Item><AdsComponent dataAdSlot='5472839718' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='5476709145' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='8931108113' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}
                            </Stack> : null}


                        {getPaginatedData() && getPaginatedData().length >= 1 ? <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                            p={1}
                            style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                        >
                            <Item>
                                <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={() => goToTop()} title="Up">
                                    <ArrowCircleUpIcon fontSize="large" />
                                </IconButton>
                            </Item>
                        </Stack> : null}


                        {<div style={{ paddingBottom: width < 500 ? 25 : 100, paddingTop: width < 500 ? 25 : 50, /*paddingRight: "25px",*/ marginTop: 5, marginBottom: 70 }} />}


                    </div>
                </div>
            </div>
        </>
    );
}