import { Avatar, Box, Grid, IconButton, Paper as Paperx, Zoom } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedIcon from '@mui/icons-material/Feed';
import GitHubIcon from '@mui/icons-material/GitHub';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, ImageList, ImageListItem, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import Parser from 'html-react-parser';
import React, { Suspense, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { batch, useDispatch, useSelector } from 'react-redux';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import $ from 'jquery';
import Draggable from 'react-draggable';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { extractDomain, formatHashtags, isOnlyWhitespace, isValidHashtagString, isValidUrl, shortGender, truncateFullName } from "../helpers/util";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { SuspenseImg } from "../helpers/SuspenseImage";

import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import GroupsIcon from '@mui/icons-material/Groups';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { styled } from '@mui/system';
import { GoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";
import { commentActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, outofscopeActions, profilActions, publisherActions, reportActions, spaceActions, spaceswitchActions } from "../actions";
import { appreciationActions } from "../actions/appreciation.actions";
import { alertConstants, commentConstants, listonespaceswitchConstants, spaceswitchConstants, switchConstants, userConstants, workerConstants } from "../constants";
import { getErrorMessageFetch } from "../helpers";
import { DiscussionClass } from "../models/discussion";
import { GivingClass } from "../models/giving";
import { roundTimeAgo, truncateNumber } from "../services/util.service";
import { TikTokIcon, TwitchBlueIcon } from "./CustomSVG";

import SendIcon from '@material-ui/icons/Send';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import NumbersSharpIcon from '@mui/icons-material/NumbersSharp';
import PollIcon from '@mui/icons-material/Poll';
import ReplyIcon from '@mui/icons-material/Reply';
import ShareIcon from '@mui/icons-material/Share';
import { CommentClass } from "../models/comment.js";
import { MessageBlank, MessageLeft, MessageRight, parseTimeAgoToTimestamp } from "./Message.js";

import { Client } from '@stomp/stompjs';
// import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

import { useLocation, useNavigate } from "react-router-dom";
import { WebSocketServer } from "ws";
import { EditInfoClass, ImageClass, OneEditInfoClass } from "../models/image.js";
import { ReportClass } from "../models/report.js";
import { BeginText, Spinner, SpinnerLoading } from "../spinner/spinner.js";
import { LogoSpace } from "./CustomDiscussionPagination.js";
import { DraggableReportPopup } from "./ProfilItem.js";

import Menu from '@mui/material/Menu';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { OutOfScopeClass } from "../models/outofscope.js";

Object.assign(global, { WebSocketServer });


const COMMUNO = workerConstants.WORKER
const preUrlDiscussion = workerConstants.PRE_URL_DISCUSSION
const preUrlProfil = workerConstants.PRE_URL_PROFIL

const styleTextField = {
    // width: 320,
    // margin: '15px',
    marginBottom: '15px',
    // maxWidth: '100%',
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

const styleTextFieldAdditional1 = {
    width: 320,
    marginBottom: "10px",
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

const styleTextFieldAdditional2 = {
    width: 320,
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

const styleTextFieldAdditional3 = {
    // width: 320,
    // margin: '15px',
    // marginLeft: "15px",
    // marginTop: "15px",
    marginTop: '15px',
    marginBottom: '15px',
    // maxWidth: '100%',
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});


const client = new Client({
    brokerURL: workerConstants.WS_WORKER,
    // webSocketFactory: function () {
    //     // Note that the URL is different from the WebSocket URL
    //     return new SockJS('https://service.onelike.app/ws');
    // },
    // forceBinaryWSFrames : true,
    debug: function (str) {
        console.log('STOMP: ' + str);
    },
});



export function SpaceItem(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const { id, name, type, description, urlParam, numcreators, numdiscussions } = props.data;
    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(400);

    // event handler
    const handleChange = event => {
        setInputText(event.target.value);
    };

    const path = "/space/" + urlParam;

    const openOneSpace = () => {
        dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
        dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
        // window.open(path, "_self")
        navigate(path)
        resetPage()
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    return (
        <Container>
            <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                <a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none", cursor: "pointer" }} onClick={openOneSpace}>{name} <LogoSpace name={name} color={"#fff"} /></a>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.875rem' }}>
                    <InfoIcon className="notDraggable text-white" onClick={e => handleOpenInfoPopup()} style={{ float: 'right', cursor: "pointer" }} />
                </div>
            </h6>

            <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"space"} />

            <Card sx={styleMobLarge2}>

                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" style={{ color: theme === 'dark' ? "#fff" : "#000" }}>
                        {type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ textDecoration: "underline dotted", cursor: "pointer" }}>
                        {description}
                    </Typography>
                </CardContent>
                {/* <Button size="small" variant="contained" style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }}>Enter</Button> */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    p={0}
                >
                    <Stack
                        direction={"row"}
                        justifyContent="flex-start"
                        alignItems="left"
                        spacing={0}
                        p={1}
                    >
                        <Item>
                            <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 4, fontWeight: 'bold' }}>{truncateNumber(numdiscussions)}</span>
                                <FeedIcon color="action" style={{ color: "#fff" }} />
                            </Box>
                        </Item>
                        {/* <Item><Badge badgeContent={1000000} max={1000000} color="primary"><TopicIcon color="action" style={{ color: "#fff" }} /></Badge></Item> */}
                        {/* <Item><Badge badgeContent={1000000} max={1000000} color="primary"><GroupsIcon color="action" style={{ color: "#fff" }} /></Badge></Item> */}
                        <Item style={{ paddingLeft: 0 }}>
                            <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 4, fontWeight: 'bold' }}>{truncateNumber(numcreators)}</span>
                                <GroupsIcon color="action" style={{ color: "#fff" }} />
                            </Box>
                        </Item>
                    </Stack>
                    {/* <Item style={{ paddingLeft: 0 }}><IconButton style={{ background: "#1976d2", color: "#fff" }} onClick={() => { window.open(path, "_self"); }} title="Enter in this Room">
                        <EastIcon />
                    </IconButton></Item> */}
                    <Item style={{ paddingLeft: 0 }}>
                        <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={openOneSpace}><ArrowCircleRightIcon /></Button>
                    </Item>
                </Stack>
            </Card>
        </Container>
    );
}

export function CreatePost({ key, index, name, setSpaceNameForFeed, spaceid, post, setPost, numField, setNumField, inputText, setInputText, characterLimit, open, setOpen, spacename, layoutPreview, setLayoutPreview, editMode, user, username, fullname, profileUrl, authenticated, loggedIn, images, setImages, uploadLimit, uploadPreviewLimit, inputUpload, spaceList, onFeed, titleRef, bodyRef, hashtagsRef, link1Ref, link2Ref, link3Ref, link4Ref }) {
    const [layout, setLayout] = useState(1);
    const dispatch = useDispatch();
    const [sending, setSending] = useState(false);

    // For Edit
    const [postForEdit, setPostForEdit] = useState(post)
    const [inputTextForEdit, setInputTextForEdit] = useState(inputText);
    const [numFieldForEdit, setNumFieldForEdit] = useState(numField)
    const [imagesForEdit, setImagesForEdit] = useState(images)
    const [editImageInfos, setEditImageInfos] = useState([])

    const titleRefForEdit = useRef('')
    const bodyRefForEdit = useRef('')
    const hashtagsRefForEdit = useRef('')
    const link1RefForEdit = useRef('')
    const link2RefForEdit = useRef('')
    const link3RefForEdit = useRef('')
    const link4RefForEdit = useRef('')

    const d = useSelector((state) => state.discussion.done);
    const dis = useSelector((state) => state.discussion.discussionList);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.discussion.error);
    const [error, setError] = useState(e);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))
    const [spname, setSpname] = useState(localStorage.getItem("spacename"))

    const lop = useSelector(state => state.listonespaceswitch);
    const ss = useSelector(state => state.spaceswitch);
    const l = useSelector(state => state.discussion.loading);

    const [openSignInPopup, setOpenDeletePopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenDeletePopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenDeletePopup(false);
    }

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    // useEffect(() => {
    //     if (editMode) {
    //         if (titleRefForEdit && titleRefForEdit.current && titleRefForEdit.current.value) {
    //             titleRefForEdit.current.value = postForEdit.title
    //         }

    //         if (bodyRefForEdit && bodyRefForEdit.current && bodyRefForEdit.current.value) {
    //             bodyRefForEdit.current.value = postForEdit.post
    //         }
    //     }
    // }, [])

    useEffect(() => {
        setDone(d)
    }, [d])

    useEffect(() => {
        setError(e)
    }, [e])

    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (sending) {
            if (d) {
                if (e) {
                    // console.log("done1 : " + done)
                    // console.log("error1 : " + error)
                    // setShowSpinner(false);
                    if (onFeed) {
                        dispatch(discussionActions.getFeedFilter())
                        dispatch(discussionActions.getFeed());
                    } else {
                        dispatch(discussionActions.getDiscussionList(urlp))
                    }
                    //toast.error("Error");
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'An error has occurred. Please try again ...',
                    //     showConfirmButton: true,
                    //     timer: 5000
                    // })
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    // console.log("done2 : " + done)
                    // console.log("error2 : " + error)
                    // setShowSpinner(false);

                    resetPage()

                    if (dis && !dis.suspended && !dis.banned) {
                        if (onFeed) {

                            dispatch(getFeedInfos())

                        } else {
                            if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                                if (ss == spaceswitchConstants.FEED_NR) {
                                    dispatch(discussionActions.getFeedFilter())
                                    dispatch(discussionActions.getFeed());
                                }
                                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                    dispatch(spaceActions.getSpaceList());
                                }
                                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                    dispatch(discussionActions.getMyDiscussionList());
                                }
                                if (ss == spaceswitchConstants.LIGHT_NR) {
                                    dispatch(discussionActions.getLightDiscussionList());
                                }
                            }

                            if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                    dispatch(discussionActions.getDiscussionList(urlp))
                                }
                                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                    dispatch(discussionActions.getMyDiscussionList());
                                }
                                if (ss == spaceswitchConstants.LIGHT_NR) {
                                    dispatch(discussionActions.getLightDiscussionList());
                                }
                            }

                            dispatch(getAllInfosForSpace())
                        }
                        // toast.success("Saved");
                        if (editMode) {
                            Toast.fire({
                                icon: "success",
                                title: "Successfully updated"
                            });
                        } else {
                            Toast.fire({
                                icon: "success",
                                title: "Successfully shared"
                            });
                        }
                    } else {
                        Toast.fire({
                            icon: "warning",
                            title: "Sorry, your sharing, commenting, and messaging are temporarily restricted."
                        });
                        if (onFeed) {

                            dispatch(getFeedInfos())

                        } else {
                            if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                                if (ss == spaceswitchConstants.FEED_NR) {
                                    dispatch(discussionActions.getFeedFilter())
                                    dispatch(discussionActions.getFeed());
                                }
                                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                    dispatch(spaceActions.getSpaceList());
                                }
                                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                    dispatch(discussionActions.getMyDiscussionList());
                                }
                                if (ss == spaceswitchConstants.LIGHT_NR) {
                                    dispatch(discussionActions.getLightDiscussionList());
                                }
                            }

                            if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                    dispatch(discussionActions.getDiscussionList(urlp))
                                }
                                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                    dispatch(discussionActions.getMyDiscussionList());
                                }
                                if (ss == spaceswitchConstants.LIGHT_NR) {
                                    dispatch(discussionActions.getLightDiscussionList());
                                }
                            }

                            dispatch(getAllInfosForSpace())
                        }
                    }
                }
            }
        }
    }, [d]);

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    const getFeedInfos = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    // useEffect(() => {
    //     setPost(post)
    // }, [post])

    function validationUrl(link) {
        return post && link && isValidUrl(link) && !isOnlyWhitespace(link)
    }

    const toBoolean = (x) => {
        return !!x
    }

    // var checkBeforeShare = isValidHashtagString(post.hashtags) && validationUrl(post.link1) && validationUrl(post.link2) && validationUrl(post.link3) && validationUrl(post.link4)
    var checkBeforeShare = (toBoolean(post.title) && toBoolean(post.post)) || (toBoolean(post.title) && ((images && images.length > 0) || (imagesForEdit && imagesForEdit.length > 0)))

    const handleSubmit = (e) => {
        e.preventDefault();

        if (authenticated) {
            // console.log(titleRef)
            // console.log(bodyRef)
            // if (titleRef && titleRef.current && titleRef.current.value) {
            //     console.log(titleRef.current.value)
            // }
            // if (bodyRef && bodyRef.current && bodyRef.current.value) {
            //     console.log(bodyRef.current.value)
            // }

            var discussion = new DiscussionClass();
            if (editMode) {
                discussion.duid = postForEdit.duid;

                if (layout == 1) {
                    if (titleRefForEdit && titleRefForEdit.current && titleRefForEdit.current.value) {
                        discussion.title = titleRefForEdit.current.value
                    }
                    if (bodyRefForEdit && bodyRefForEdit.current && bodyRefForEdit.current.value) {
                        discussion.post = bodyRefForEdit.current.value
                    }
                } else {
                    discussion.title = postForEdit.title;
                    discussion.post = postForEdit.post;
                }

                if (layout == 2) {
                    if (hashtagsRefForEdit && hashtagsRefForEdit.current && hashtagsRefForEdit.current.value) {
                        discussion.hashtags = hashtagsRefForEdit.current.value
                    }
                    if (link1RefForEdit && link1RefForEdit.current && link1RefForEdit.current.value) {
                        discussion.link1 = link1RefForEdit.current.value
                    }
                    if (link2RefForEdit && link2RefForEdit.current && link2RefForEdit.current.value) {
                        discussion.link2 = link2RefForEdit.current.value
                    }
                    if (link3RefForEdit && link3RefForEdit.current && link3RefForEdit.current.value) {
                        discussion.link3 = link3RefForEdit.current.value
                    }
                    if (link4RefForEdit && link4RefForEdit.current && link4RefForEdit.current.value) {
                        discussion.link4 = link4RefForEdit.current.value
                    }
                } else {
                    discussion.hashtags = postForEdit.hashtags;
                    discussion.link1 = postForEdit.link1;
                    discussion.link2 = postForEdit.link2;
                    discussion.link3 = postForEdit.link3;
                    discussion.link4 = postForEdit.link4;
                }

                discussion.createdat = postForEdit.createdat;
                discussion.updatedat = postForEdit.updatedat;
                discussion.createdby = postForEdit.createdby;

                discussion.space = spaceid;

                if (onFeed) {
                    discussion.space = postForEdit.spaceid;
                }

                if (discussion && discussion.post && isOnlyWhitespace(discussion.post)) {
                    if (imagesForEdit && imagesForEdit.length == 0) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please write a body"
                        });

                        return;
                    }
                }
            } else {
                discussion.duid = post.duid;

                if (layout == 1) {
                    if (titleRef && titleRef.current && titleRef.current.value) {
                        discussion.title = titleRef.current.value
                    }
                    if (bodyRef && bodyRef.current && bodyRef.current.value) {
                        discussion.post = bodyRef.current.value
                    }
                } else {
                    discussion.title = post.title;
                    discussion.post = post.post;
                }

                if (layout == 2) {
                    if (hashtagsRef && hashtagsRef.current && hashtagsRef.current.value) {
                        discussion.hashtags = hashtagsRef.current.value
                    }
                    if (link1Ref && link1Ref.current && link1Ref.current.value) {
                        discussion.link1 = link1Ref.current.value
                    }
                    if (link2Ref && link2Ref.current && link2Ref.current.value) {
                        discussion.link2 = link2Ref.current.value
                    }
                    if (link3Ref && link3Ref.current && link3Ref.current.value) {
                        discussion.link3 = link3Ref.current.value
                    }
                    if (link4Ref && link4Ref.current && link4Ref.current.value) {
                        discussion.link4 = link4Ref.current.value
                    }
                } else {
                    discussion.hashtags = post.hashtags;
                    discussion.link1 = post.link1;
                    discussion.link2 = post.link2;
                    discussion.link3 = post.link3;
                    discussion.link4 = post.link4;
                }

                discussion.createdat = post.createdat;
                discussion.updatedat = post.updatedat;
                discussion.createdby = post.createdby;

                discussion.space = spaceid;

                if (onFeed) {
                    discussion.space = post.spaceid;
                }

                if (discussion && discussion.space == "") {
                    Toast.fire({
                        icon: "warning",
                        title: "Please select a Space"
                    });

                    return;
                }

                if (discussion && discussion.space === 1) {
                    if (!(!!user && !!user.role && !!user.role.includes("MODERATOR")) &&
                        !(!!user && !!user.role && !!user.role.includes("ADMIN"))) {
                        Toast.fire({
                            icon: "warning",
                            title: "Sorry, you are not allowed to Create a Content in this Space"
                        });

                        return;
                    }
                }


                if (discussion && discussion.post && isOnlyWhitespace(discussion.post)) {
                    if (images && images.length == 0) {
                        Toast.fire({
                            icon: "warning",
                            title: "Please write a body"
                        });

                        return;
                    }
                }
            }

            var checkBeforeShareBeforeSending = (toBoolean(discussion.title) && toBoolean(discussion.post)) || (toBoolean(discussion.title) && ((images && images.length > 0) || (imagesForEdit && imagesForEdit.length > 0)))
            if (!checkBeforeShareBeforeSending) {
                Toast.fire({
                    icon: "warning",
                    title: "At least a title and a body are required"
                });

                return;
            }

            if (discussion && discussion.post && !isOnlyWhitespace(discussion.post) && discussion.post.length > 2000) {
                Toast.fire({
                    icon: "warning",
                    title: "Please write a short body"
                });

                return;
            }

            if (discussion && discussion.title && isOnlyWhitespace(discussion.title)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please write a title"
                });

                return;
            }

            if (discussion && discussion.title && !isOnlyWhitespace(discussion.title) && discussion.title.length > 300) {
                Toast.fire({
                    icon: "warning",
                    title: "Please write a short title"
                });

                return;
            }

            if (discussion && discussion.hashtags && !isValidHashtagString(discussion.hashtags)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please write correctly hashtags"
                });

                return;
            }

            if (editMode) {
                if (numFieldForEdit && numFieldForEdit >= 1 && discussion && discussion.link1 && !isValidUrl(discussion.link1)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the first link"
                    });

                    return;
                }

                if (numFieldForEdit && numFieldForEdit >= 2 && discussion && discussion.link2 && !isValidUrl(discussion.link2)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the second link"
                    });

                    return;
                }

                if (numFieldForEdit && numFieldForEdit >= 3 && discussion && discussion.link3 && !isValidUrl(discussion.link3)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the third link"
                    });

                    return;
                }

                if (numFieldForEdit && numFieldForEdit >= 4 && discussion && discussion.link4 && !isValidUrl(discussion.link4)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the fourth link"
                    });

                    return;
                }
            } else {
                if (numField && numField >= 1 && discussion && discussion.link1 && !isValidUrl(discussion.link1)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the first link"
                    });

                    return;
                }

                if (numField && numField >= 2 && discussion && discussion.link2 && !isValidUrl(discussion.link2)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the second link"
                    });

                    return;
                }

                if (numField && numField >= 3 && discussion && discussion.link3 && !isValidUrl(discussion.link3)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the third link"
                    });

                    return;
                }

                if (numField && numField >= 4 && discussion && discussion.link4 && !isValidUrl(discussion.link4)) {
                    Toast.fire({
                        icon: "warning",
                        title: "Please write correctly the fourth link"
                    });

                    return;
                }
            }



            if (editMode) {
                if (numFieldForEdit === 3) {
                    discussion.link4 = ""
                }

                if (numFieldForEdit === 2) {
                    discussion.link3 = ""
                    discussion.link4 = ""
                }

                if (numFieldForEdit === 1) {
                    discussion.link2 = ""
                    discussion.link3 = ""
                    discussion.link4 = ""
                }
            } else {
                if (numField === 3) {
                    discussion.link4 = ""
                }

                if (numField === 2) {
                    discussion.link3 = ""
                    discussion.link4 = ""
                }

                if (numField === 1) {
                    discussion.link2 = ""
                    discussion.link3 = ""
                    discussion.link4 = ""
                }
            }

            // discussion.spacename = name;

            // console.log(images)

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }

            var discussionInfos = JSON.parse(JSON.stringify(discussion, replacer));
            if (editMode) {
                setSending(true)

                const newImages = []
                if (imagesForEdit && imagesForEdit.length != 0) {
                    for (var i = 0; ((i < imagesForEdit.length) && (imagesForEdit.length <= uploadLimit)); i++) {
                        if (imagesForEdit[i].new) {
                            newImages.push(imagesForEdit[i].file)
                        }
                    }
                }

                // console.log(imagesForEdit)
                // console.log(newImages)

                var editInfoWrapper = new EditInfoClass()
                // console.log(editImageInfos)
                editInfoWrapper.editInfos = editImageInfos

                var editImageInf = JSON.parse(JSON.stringify(editInfoWrapper));
                // console.log(editImageInf)

                dispatch(discussionActions.updateDiscussion(discussionInfos, newImages, editImageInf));
            } else {
                setSending(true)

                const newImages = []
                if (images && images.length != 0) {
                    for (var i = 0; ((i < images.length) && (images.length <= uploadLimit)); i++) {
                        newImages.push(images[i].file)
                    }
                }

                dispatch(discussionActions.saveDiscussion(discussionInfos, newImages));
            }

            // dispatch(discussionActions.getDiscussionList(spacename))
        } else {
            handleOpenSignInPopup()
        }
    }

    return (
        <>
            <Spinner show={l} />
            {layout == 1 ?
                <NewPost
                    user={user}
                    name={name}
                    setSpaceNameForFeed={setSpaceNameForFeed}
                    layout={layout}
                    setLayout={setLayout}
                    post={editMode ? postForEdit : post}
                    setPost={editMode ? setPostForEdit : setPost}
                    numField={editMode ? numFieldForEdit : numField}
                    setNumField={editMode ? setNumFieldForEdit : setNumField}
                    inputText={editMode ? inputTextForEdit : inputText}
                    setInputText={editMode ? setInputTextForEdit : setInputText}
                    characterLimit={characterLimit}
                    open={open}
                    setOpen={setOpen}
                    handleSubmit={handleSubmit}
                    checkBeforeShare={checkBeforeShare}
                    layoutPreview={layoutPreview}
                    setLayoutPreview={setLayoutPreview}
                    editMode={editMode}
                    openInfoPopup={openInfoPopup}
                    handleCloseInfoPopup={handleCloseInfoPopup}
                    handleOpenInfoPopup={handleOpenInfoPopup}
                    openSignInPopup={openSignInPopup}
                    handleCloseSignInPopup={handleCloseSignInPopup}
                    images={editMode ? imagesForEdit : images}
                    setImages={editMode ? setImagesForEdit : setImages}
                    uploadLimit={uploadLimit}
                    uploadPreviewLimit={uploadPreviewLimit}
                    inputUpload={inputUpload}
                    editImageInfos={editImageInfos}
                    setEditImageInfos={setEditImageInfos}
                    spaceid={spaceid}
                    spaceList={spaceList}
                    onFeed={onFeed}
                    titleRef={editMode ? titleRefForEdit : titleRef}
                    bodyRef={editMode ? bodyRefForEdit : bodyRef}
                    hashtagsRef={editMode ? hashtagsRefForEdit : hashtagsRef}
                    link1Ref={editMode ? link1RefForEdit : link1Ref}
                    link2Ref={editMode ? link2RefForEdit : link2Ref}
                    link3Ref={editMode ? link3RefForEdit : link3Ref}
                    link4Ref={editMode ? link4RefForEdit : link4Ref}
                /> : null}
            {layout == 2 ?
                <Additional
                    name={name}
                    layout={layout}
                    setLayout={setLayout}
                    post={editMode ? postForEdit : post}
                    setPost={editMode ? setPostForEdit : setPost}
                    numField={editMode ? numFieldForEdit : numField}
                    setNumField={editMode ? setNumFieldForEdit : setNumField}
                    open={open}
                    setOpen={setOpen}
                    handleSubmit={handleSubmit}
                    checkBeforeShare={checkBeforeShare}
                    layoutPreview={layoutPreview}
                    setLayoutPreview={setLayoutPreview}
                    editMode={editMode}
                    openSignInPopup={openSignInPopup}
                    handleCloseSignInPopup={handleCloseSignInPopup}
                    openInfoPopup={openInfoPopup}
                    handleCloseInfoPopup={handleCloseInfoPopup}
                    handleOpenInfoPopup={handleOpenInfoPopup}
                    titleRef={editMode ? titleRefForEdit : titleRef}
                    bodyRef={editMode ? bodyRefForEdit : bodyRef}
                    hashtagsRef={editMode ? hashtagsRefForEdit : hashtagsRef}
                    link1Ref={editMode ? link1RefForEdit : link1Ref}
                    link2Ref={editMode ? link2RefForEdit : link2Ref}
                    link3Ref={editMode ? link3RefForEdit : link3Ref}
                    link4Ref={editMode ? link4RefForEdit : link4Ref}
                /> : null}
            {layout == 3 ?
                <PostPreview
                    name={name}
                    layout={layout}
                    setLayout={setLayout}
                    post={editMode ? postForEdit : post}
                    setPost={editMode ? setPostForEdit : setPost}
                    numField={editMode ? numFieldForEdit : numField}
                    setNumField={editMode ? setNumFieldForEdit : setNumField}
                    open={open}
                    setOpen={setOpen}
                    handleSubmit={handleSubmit}
                    checkBeforeShare={checkBeforeShare}
                    layoutPreview={layoutPreview}
                    setLayoutPreview={setLayoutPreview}
                    editMode={editMode}
                    openSignInPopup={openSignInPopup}
                    handleCloseSignInPopup={handleCloseSignInPopup}
                    openInfoPopup={openInfoPopup}
                    handleCloseInfoPopup={handleCloseInfoPopup}
                    handleOpenInfoPopup={handleOpenInfoPopup}
                    user={user}
                    username={username}
                    fullname={fullname}
                    profileUrl={profileUrl}
                    authenticated={authenticated}
                    loggedIn={loggedIn}
                    images={editMode ? imagesForEdit : images}
                    titleRef={editMode ? titleRefForEdit : titleRef}
                    bodyRef={editMode ? bodyRefForEdit : bodyRef}
                    hashtagsRef={editMode ? hashtagsRefForEdit : hashtagsRef}
                    link1Ref={editMode ? link1RefForEdit : link1Ref}
                    link2Ref={editMode ? link2RefForEdit : link2Ref}
                    link3Ref={editMode ? link3RefForEdit : link3Ref}
                    link4Ref={editMode ? link4RefForEdit : link4Ref}
                /> : null}
        </>
    );
}

export function NewPost({ user, name, setSpaceNameForFeed, spaceid, layout, setLayout, post, setPost, numField, setNumField, inputText, setInputText, characterLimit, open, setOpen, handleSubmit, checkBeforeShare, layoutPreview, setLayoutPreview, editMode, openSignInPopup, handleCloseSignInPopup, images, setImages, uploadLimit, uploadPreviewLimit, inputUpload, editImageInfos, setEditImageInfos, spaceList, onFeed, openInfoPopup, handleOpenInfoPopup, handleCloseInfoPopup, titleRef, bodyRef, hashtagsRef, link1Ref, link2Ref, link3Ref, link4Ref }) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setPost(post)
    }, [post])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                // actions
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    };

    useWindowSize()

    const goToAdditional = () => {
        setPost({ ...post, ["title"]: titleRef.current.value, ["post"]: bodyRef.current.value });
        // setPost({ ...post, ["post"]: bodyRef.current.value });
        setLayout(2)
    }

    return (
        <Container>
            <div style={{ display: 'block' }}>
                <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                    <span style={{ color: "#fff", textDecoration: "none", cursor: 'auto' }}>{name} <LogoSpace name={name} color={"#fff"} /></span>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.875rem' }}>
                        <InfoIcon className="notDraggable text-white" onClick={e => handleOpenInfoPopup()} style={{ float: 'right', cursor: "pointer" }} />
                    </div>
                </h6>
                <Card sx={styleMobLarge2}>
                    {/* <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image="/static/images/cards/contemplative-reptile.jpg"
      /> */}
                    {/* <div style={{ maxWidth: 320, margin: 15 }}><EditorComponent /></div> */}

                    <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />

                    {editMode ?
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"editcontent"} />
                        :
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"createcontent"} />
                    }

                    <Container style={{ paddingRight: 0, paddingLeft: 0, width: '90%' }}>
                        {onFeed ?
                            <SpaceSelect
                                user={user}
                                spaceList={spaceList}
                                post={post}
                                setPost={setPost}
                                setSpaceNameForFeed={setSpaceNameForFeed}
                                spaceid={spaceid}
                                editMode={editMode}

                            /> : null}

                        <TitleBody post={post} setPost={setPost} inputText={inputText} setInputText={setInputText} characterLimit={characterLimit} theme={theme} titleRef={titleRef} bodyRef={bodyRef} editMode={editMode} />

                        <InputFileUpload files={images} setFiles={setImages} uploadLimit={uploadLimit} inputUpload={inputUpload} />
                        <FileUploadList files={images} setFiles={setImages} inputUpload={inputUpload} editImageInfos={editImageInfos} setEditImageInfos={setEditImageInfos} editMode={editMode} uploadPreviewLimit={uploadPreviewLimit} />

                    </Container>
                    {/*         <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <TextField
              id="outlined-multiline-static"
              placeholder={"(Optional) Add a Link"}
              sx={styleTextFieldAdditional1}
              InputProps={{
                style: { color: 'dark' === 'dark' ? '#fff' : '#fff' } // light
              }}
            />
            <TextField
              id="outlined-multiline-static"
              placeholder={"(Optional) Add hashtags"}
              sx={styleTextFieldAdditional2}
              InputProps={{
                style: { color: 'dark' === 'dark' ? '#fff' : '#fff' } // light
              }}
            />
          </Typography>
        </CardContent> */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        p={1}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                        >
                            <Item><Button size="small" variant="contained" style={{ borderRadius: 20 }} onClick={handleSubmit} /*disabled={!checkBeforeShare}*/>{editMode ? "Edit" : "Share"}</Button></Item>
                            <Item style={{ padding: 0 }}>
                                {/* <Button size="small" variant="contained" style={{ borderRadius: 20 }} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }} onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}>Cancel</Button> */}
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}><CancelIcon /></Button>
                            </Item>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                        >
                            {/* <Item>                        
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={() => {}}><ArrowCircleLeftIcon /></Button>
                            </Item> */}
                            <Item style={{ padding: 0 }}>
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={goToAdditional}><ArrowCircleRightIcon /></Button>
                            </Item>
                        </Stack>
                    </Stack>
                    {/*         <CardActions>
          <Button size="small" variant="contained" style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }}>Share</Button>
          <Button size="small" variant="contained" style={{ marginLeft: 15, marginTop: 5, borderRadius: 20 }}>Comment</Button>
          <Badge badgeContent={4} color="primary" style={{ marginLeft: 130 }}><CommentIcon color="action" style={{ color: "#fff" }} /></Badge>
        </CardActions> */}

                </Card>
            </div>
        </Container>
    );
}

export const TitleBody = ({
    post,
    setPost,
    inputText,
    setInputText,
    characterLimit,
    theme,
    titleRef,
    bodyRef,
    editMode
}) => {



    // const handleChange = (e) => {
    //     if (e.target.id === "title") {
    //         setPost({ ...post, [e.target.id]: e.target.value });
    //     }

    //     if (e.target.id === "post") {
    //         setInputText(e.target.value);
    //         setPost({ ...post, [e.target.id]: e.target.value });
    //     }
    // }

    // useEffect(() => {
    //     console.log(bodyRef)
    //     if (titleRef.current && titleRef.current.value) {
    //         setPost({ ...post, ["title"]: titleRef.current.value });
    //     }
    //     if (bodyRef.current && bodyRef.current.value) {
    //         setInputText(bodyRef.current.value);
    //         setPost({ ...post, ["post"]: bodyRef.current.value });
    //     }
    // }, [titleRef.current, bodyRef.current])

    // const handleChange = (e) => {
    //     setInputText(e.target.value);
    // }

    return (
        <>
            <TitleComp post={post} theme={theme} ref={titleRef} editMode={editMode} />
            <BodyComp post={post} theme={theme} inputText={inputText} characterLimit={characterLimit} ref={bodyRef} editMode={editMode} />
        </>
    )
}

export const TitleComp = forwardRef(({ post, editMode, theme }, ref) => {

    return (
        <TextField
            fullWidth
            id="title"
            placeholder={"What's the topic ?!"}
            sx={styleTextFieldAdditional3}
            InputProps={{
                style: { color: theme === 'dark' ? "#fff" : "#000" } // light
            }}
            // onChange={handleChange}
            // value={post ? post.title : ""}
            defaultValue={post?.title}
            inputRef={ref}
        />
    );

})

export const BodyComp = forwardRef(({
    post,
    theme,
    editMode,
    inputText,
    characterLimit
}, ref) => {

    return (
        <TextField
            fullWidth
            id="post"
            multiline
            rows={10}
            placeholder={"What's going on ?!"}
            sx={styleTextField}
            InputProps={{
                style: { color: theme === 'dark' ? "#fff" : "#000" }, // light
            }}
            // value={post ? post.post : ""}
            // onChange={handleChange}
            defaultValue={post?.post}
            inputRef={ref}
            error={(inputText && inputText.length) > characterLimit}
            helperText={(inputText && inputText.length) > characterLimit ? "" : ""}
        />
    )
})

export function Additional({ name, layout, setLayout, post, setPost, numField, setNumField, open, setOpen, handleSubmit, checkBeforeShare, layoutPreview, setLayoutPreview, editMode, openSignInPopup, handleCloseSignInPopup, openInfoPopup, handleOpenInfoPopup, handleCloseInfoPopup, titleRef, bodyRef, hashtagsRef, link1Ref, link2Ref, link3Ref, link4Ref }) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setPost(post)
    }, [post])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }


    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(400);
    // event handler
    const handleChange = e => {
        // setInputText(e.target.value);

        if (e.target.id === "hashtags") {
            setPost({ ...post, [e.target.id]: e.target.value });
        }

        if (e.target.id === "link1") {
            setPost({ ...post, [e.target.id]: e.target.value });
        }

        if (e.target.id === "link2") {
            setPost({ ...post, [e.target.id]: e.target.value });
        }

        if (e.target.id === "link3") {
            setPost({ ...post, [e.target.id]: e.target.value });
        }

        if (e.target.id === "link4") {
            setPost({ ...post, [e.target.id]: e.target.value });
        }
    };

    const AdditionalTextField = () => {
        return (
            <TextField
                id="hashtags"
                placeholder={"Add hashtags"}
                sx={styleTextFieldAdditional3}
                InputProps={{
                    style: { color: theme === 'dark' ? "#fff" : "#000" } // light
                }}
                value={post ? post.hashtags : ""}
            // onChange={handleChange}
            />
        );
    }

    const AdditionalTextFieldForLink = () => {
        setNumField(numField++);
    }

    const handleAddTextField = () => {
        var numTmp = numField + 1;
        setNumField(numTmp);
    };

    const handleRemoveLastTextField = (e) => {
        var numTmp = numField - 1;
        // setPost({ ...post, [e.target.id]: "" });
        setNumField(numTmp);
    };

    const goToNewPost = () => {
        setPost({
            ...post,
            ["hashtags"]: hashtagsRef.current.value,
            ["link1"]: link1Ref.current.value,
            ["link2"]: link2Ref.current.value,
            ["link3"]: link3Ref.current.value,
            ["link4"]: link4Ref.current.value
        });
        // setPost({ ...post, ["link1"]: link1Ref.current.value });
        // setPost({ ...post, ["link2"]: link2Ref.current.value });
        // setPost({ ...post, ["link3"]: link3Ref.current.value });
        // setPost({ ...post, ["link4"]: link4Ref.current.value });
        setLayout(1)
    }

    const goToPreview = () => {
        setPost({
            ...post,
            ["hashtags"]: hashtagsRef.current.value,
            ["link1"]: link1Ref.current.value,
            ["link2"]: link2Ref.current.value,
            ["link3"]: link3Ref.current.value,
            ["link4"]: link4Ref.current.value
        });
        setLayout(3)
    }

    return (
        <Container>
            <div style={{ display: 'block' }}>
                <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                    <span style={{ color: "#fff", textDecoration: "none", cursor: 'auto' }}>{name} <LogoSpace name={name} color={"#fff"} /></span>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.875rem' }}>
                        <InfoIcon className="notDraggable text-white" onClick={e => handleOpenInfoPopup()} style={{ float: 'right', cursor: "pointer" }} />
                    </div>
                </h6>
                <Card sx={styleMobLarge2}>
                    <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />

                    {editMode ?
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"editcontent"} />
                        :
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"createcontent"} />
                    }

                    <Container style={{ height: 490, paddingRight: 0, paddingLeft: 0, width: '90%' }}>
                        <Box style={{ paddingTop: 15, paddingBottom: 4 }}>

                            <HashtagsComp theme={theme} editMode={editMode} post={post} ref={hashtagsRef} />

                            {/* <div>
                                {post && post.hashtags && !isValidHashtagString(post.hashtags) ? (<FormHelperText error={true}>No Valid Hashtags</FormHelperText>) : null}
                            </div> */}
                        </Box>
                        {numField >= 1 ? <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                            <LinkRefComp theme={theme} editMode={editMode} post={post} link={"link1"} ref={link1Ref} />
                            {/* <TextField
                                fullWidth
                                id={"link1"}
                                placeholder={"Add a link"}
                                // sx={styleTextField}
                                InputProps={{
                                    style: { color: theme === 'dark' ? "#fff" : "#000" } // light
                                }}
                                // value={post ? post.link1 : ""}
                                // onChange={handleChange}
                                defaultValue={editMode ? post?.link1 : ""}
                                inputRef={link1Ref}
                            /> */}
                            {/* <div>
                                {post && post.link1 && !isValidUrl(post.link1) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                            </div> */}
                        </Box> : null}
                        {numField >= 2 ? <Box style={{ paddingTop: 4, paddingBottom: 4 }}>

                            <LinkRefComp theme={theme} editMode={editMode} post={post} link={"link2"} ref={link2Ref} />

                            {/* <div>
                                {post && post.link2 && !isValidUrl(post.link2) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                            </div> */}
                        </Box> : null}
                        {numField >= 3 ? <Box style={{ paddingTop: 4, paddingBottom: 4 }}>

                            <LinkRefComp theme={theme} editMode={editMode} post={post} link={"link3"} ref={link3Ref} />

                            {/* <div>
                                {post && post.link3 && !isValidUrl(post.link3) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                            </div> */}
                        </Box> : null}
                        {numField >= 4 ? <Box style={{ paddingTop: 4, paddingBottom: 4 }}>

                            <LinkRefComp theme={theme} editMode={editMode} post={post} link={"link4"} ref={link4Ref} />

                            {/* <div>
                                {post && post.link4 && !isValidUrl(post.link4) ? <FormHelperText error={true}>No Valid Url</FormHelperText> : null}
                            </div> */}
                        </Box> : null}
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            p={0}
                        >
                            {numField < 4 ? <>
                                <Item>
                                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}><IconButton style={{ background: "#1976d2", color: "#fff" }} onClick={handleAddTextField} title="Add TextField">
                                        <AddBoxIcon />
                                    </IconButton></Box>
                                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} onClick={handleAddTextField}><AddBoxIcon /></Button>
                                </Item>
                            </> : null}
                            {numField > 1 ? <>
                                <Item style={{ paddingTop: 8 }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }}>
                                        <IconButton style={{ background: "#1976d2", color: "#fff" }} onClick={handleRemoveLastTextField} title="Remove TextField">
                                            <IndeterminateCheckBoxIcon />
                                        </IconButton>
                                    </Box>
                                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} onClick={handleRemoveLastTextField}><IndeterminateCheckBoxIcon /></Button>
                                </Item>
                            </> : null}
                        </Stack>
                    </Container>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        p={1}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                        >
                            <Item><Button size="small" variant="contained" style={{ borderRadius: 20 }} onClick={handleSubmit} /*disabled={!checkBeforeShare}*/>{editMode ? "Edit" : "Share"}</Button></Item>
                            <Item style={{ padding: 0 }}>
                                {/* <Button size="small" variant="contained" style={{ borderRadius: 20 }} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }} onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}>Cancel</Button> */}
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}><CancelIcon /></Button>
                            </Item>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                        >
                            <Item>
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}*/ onClick={goToNewPost}><ArrowCircleLeftIcon /></Button>
                            </Item>
                            <Item style={{ padding: 0 }}>
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={goToPreview}><ArrowCircleRightIcon /></Button>
                            </Item>
                        </Stack>
                    </Stack>
                </Card>
            </div>
        </Container>
    );
}

const HashtagsComp = forwardRef(({ theme, editMode, post }, ref) => {
    return (
        <TextField
            fullWidth
            id="hashtags"
            placeholder={"Add hashtags"}
            // sx={styleTextFieldAdditional3}
            InputProps={{
                style: { color: theme === 'dark' ? "#fff" : "#000" } // light
            }}
            // value={post ? post.hashtags : ""}
            // onChange={handleChange}
            defaultValue={post?.hashtags}
            inputRef={ref}
        />
    )
})

const LinkRefComp = forwardRef(({ theme, editMode, post, link }, ref) => {
    return (
        <TextField
            fullWidth
            id={link}
            placeholder={"Add a link"}
            // sx={styleTextField}
            InputProps={{
                style: { color: theme === 'dark' ? "#fff" : "#000" } // light
            }}
            // value={post ? post.link1 : ""}
            // onChange={handleChange}
            defaultValue={post ? post[link] : ""}
            inputRef={ref}
        />
    )
})

export function PostPreview({ name, layout, setLayout, post, setPost, numField, setNumField, open, setOpen, handleSubmit, checkBeforeShare, layoutPreview, setLayoutPreview, editMode, openSignInPopup, handleCloseSignInPopup, user, username, fullname, profileUrl, authenticated, loggedIn, images, openInfoPopup, handleOpenInfoPopup, handleCloseInfoPopup, titleRef, bodyRef, hashtagsRef, link1Ref, link2Ref, link3Ref, link4Ref }) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setPost(post)
    }, [post])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(2000);
    const [isReadMoreShownTitle, setReadMoreShownTitle] = useState(false)
    const [isReadMoreShownPost, setReadMoreShownPost] = useState(false)

    // event handler
    const handleChange = event => {
        setInputText(event.target.value);
    };

    var link1 = validationUrl(post.link1) ? "\"" + post.link1 + "\"" : "";
    var domain1 = validationUrl(post.link1) ? extractDomain(post.link1) : "";

    var link2 = validationUrl(post.link2) ? "\"" + post.link2 + "\"" : "";
    var domain2 = validationUrl(post.link2) ? extractDomain(post.link2) : "";

    var link3 = validationUrl(post.link3) ? "\"" + post.link3 + "\"" : "";
    var domain3 = validationUrl(post.link3) ? extractDomain(post.link3) : "";

    var link4 = validationUrl(post.link4) ? "\"" + post.link4 + "\"" : "";
    var domain4 = validationUrl(post.link4) ? extractDomain(post.link4) : "";

    function validationUrl(link) {
        return post && link && isValidUrl(link) && !isOnlyWhitespace(link)
    }

    return (
        <Container>
            <div style={{ display: 'block' }}>
                <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                    <span style={{ color: "#fff", textDecoration: "none", cursor: 'auto' }}>{name} <LogoSpace name={name} color={"#fff"} /></span>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.875rem' }}>
                        <InfoIcon className="notDraggable text-white" onClick={e => handleOpenInfoPopup()} style={{ float: 'right', cursor: "pointer" }} />
                    </div>
                </h6>
                <Card sx={styleMobLarge2}>
                    <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />

                    {editMode ?
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"editcontent"} />
                        :
                        <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"createcontent"} />
                    }

                    <Box style={{ height: 'auto', width: '100%' }}>
                        <div style={{ margin: 15 }}>
                            <Typography gutterBottom variant="h6" component="div" style={{ overflowWrap: 'break-word', color: theme === 'dark' ? "#fff" : "#000" }}>
                                {post && post.title && post.title.length > 120 ?
                                    <ReadMoreReadLess limit={120} isReadMoreShown={isReadMoreShownTitle} setReadMoreShown={setReadMoreShownTitle}>
                                        {post.title}
                                    </ReadMoreReadLess> :
                                    post.title
                                }
                            </Typography>
                        </div>
                        <div class="bg-primary bg-gradient text-white" style={{ padding: '2px 5px 2px 5px', borderRadius: 5, display: 'inline-block', marginLeft: 15 }} ><b>0 s</b></div>
                        <ImageBundle files={images} />
                        <div style={{ margin: '15px 15px 0px 15px' }}>
                            <Typography gutterBottom variant="body1" component="div" style={{ whiteSpace: 'pre-line', color: (post && post.post && post.post.length) > characterLimit ? '#ff0000' : (theme === 'dark' ? "#fff" : "#000") }}>
                                {post && post.post && post.post.length > 300 ?
                                    <ReadMoreReadLess limit={300} isReadMoreShown={isReadMoreShownPost} setReadMoreShown={setReadMoreShownPost}>
                                        {post.post}
                                    </ReadMoreReadLess> :
                                    post.post
                                }
                            </Typography>
                            <div style={{ height: 15, marginLeft: 15, marginBottom: 10 }}>
                                {(post && post.post && post.post.length) > characterLimit ? (<FormHelperText error={true}>Long Text</FormHelperText>) : null}
                                <span style={{ color: theme === 'dark' ? "#fff" : "#000", float: "right" }}>
                                    {post && post.post ?
                                        <>
                                            {post && post.post && post.post.length}/{characterLimit}
                                        </>
                                        :
                                        <>
                                            {"0"}/{characterLimit}
                                        </>}
                                </span>
                            </div>
                        </div>
                        {authenticated && (!isOnlyWhitespace(post.title) || !isOnlyWhitespace(post.post) || (images && images.length != 0)) ? <><Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            style={{ display: 'flex' }}
                            p={2}
                        >
                            <Item style={{ padding: 0, cursor: 'pointer' }}><Avatar alt={truncateFullName(fullname)} src={profileUrl} /></Item>
                            <Item style={{ cursor: 'pointer', color: theme === 'dark' ? "#fff" : "#000" }}>{truncateFullName(fullname)}</Item>
                        </Stack></> : null}
                        {(!isOnlyWhitespace(post.link1) && validationUrl(post.link1)) || (!isOnlyWhitespace(post.link2) && validationUrl(post.link2)) || (!isOnlyWhitespace(post.link3) && validationUrl(post.link3)) || (isOnlyWhitespace(post.link4) && validationUrl(post.link4)) ?
                            <Box sx={{ textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left', xl: 'left' } }}>
                                <div style={{ height: 20, margin: '0px 15px 15px 15px' }}>
                                    <Typography gutterBottom variant="body2" component="div" style={{ color: theme === 'dark' ? "#fff" : "#000" }} sx={{ textAlign: { sm: 'center', md: 'center', lg: 'center', xl: 'center' } }}>
                                        More on:
                                    </Typography>
                                </div>
                                <div style={{ height: 'auto', margin: 15 }}>
                                    <Typography gutterBottom variant="body2" component="div" style={{ color: theme === 'dark' ? "#fff" : "#000" }} sx={{ textAlign: { sm: 'center', md: 'center', lg: 'center', xl: 'center' } }}>
                                        <Grid
                                            container
                                            // spacing={4}
                                            // className="marginLaptop"
                                            justifyItems="left"
                                        // style={{ PaddingRight: "1000px" }}
                                        >
                                            {post.link1 && numField >= 1 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <p>{Parser(`<a href=${link1} target='_blank'>${domain1}</a>`)}</p>
                                            </Grid> : null}
                                            {post.link2 && numField >= 2 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <p>{Parser(`<a href=${link2} target='_blank'>${domain2}</a>`)}</p>
                                            </Grid> : null}
                                            {post.link3 && numField >= 3 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <p>{Parser(`<a href=${link3} target='_blank'>${domain3}</a>`)}</p>
                                            </Grid> : null}
                                            {post.link4 && numField >= 4 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <p>{Parser(`<a href=${link4} target='_blank'>${domain4}</a>`)}</p>
                                            </Grid> : null}
                                        </Grid>
                                    </Typography>
                                </div>
                            </Box> : null}
                        <div style={{ height: 'auto', margin: '0px 15px 0px 15px' }}>
                            <Typography gutterBottom variant="body2" component="div" style={{ color: theme === 'dark' ? "#fff" : "#000" }}>
                                {post && post.hashtags && isValidHashtagString(post.hashtags) && !isOnlyWhitespace(post.hashtags) ? formatHashtags(post.hashtags) : ""}
                            </Typography>
                        </div>
                    </Box>
                    {/*         <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <TextField
              id="outlined-multiline-static"
              placeholder={"(Optional) Add a Link"}
              sx={styleTextFieldAdditional1}
              InputProps={{
                style: { color: 'dark' === 'dark' ? '#fff' : '#fff' } // light
              }}
            />
            <TextField
              id="outlined-multiline-static"
              placeholder={"(Optional) Add hashtags"}
              sx={styleTextFieldAdditional2}
              InputProps={{
                style: { color: 'dark' === 'dark' ? '#fff' : '#fff' } // light
              }}
            />
          </Typography>
        </CardContent> */}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        p={1}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                        >
                            <Item><Button size="small" variant="contained" style={{ borderRadius: 20 }} onClick={handleSubmit} /*disabled={!checkBeforeShare}*/>{editMode ? "Edit" : "Share"}</Button></Item>
                            <Item style={{ padding: 0 }}>
                                {/* <Button size="small" variant="contained" style={{ borderRadius: 20 }} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }} onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}>Cancel</Button> */}
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} */ onClick={() => { editMode ? setLayoutPreview(1) : setOpen(false) }}><CancelIcon /></Button>
                            </Item>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                        >
                            <Item>
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} /*sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}*/ onClick={() => setLayout(2)}><ArrowCircleLeftIcon /></Button>
                            </Item>
                            {/* <Item style={{ padding: 0 }}>                            
                                <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={() => {}}><ArrowCircleRightIcon /></Button>
                            </Item> */}
                        </Stack>
                    </Stack>
                    {/*         <CardActions>
          <Button size="small" variant="contained" style={{ marginLeft: 6, marginTop: 5, borderRadius: 20 }}>Share</Button>
          <Button size="small" variant="contained" style={{ marginLeft: 15, marginTop: 5, borderRadius: 20 }}>Comment</Button>
          <Badge badgeContent={4} color="primary" style={{ marginLeft: 130 }}><CommentIcon color="action" style={{ color: "#fff" }} /></Badge>
        </CardActions> */}
                </Card>
            </div>
        </Container>
    );
}

export const ReadMoreReadLess = ({ isReadMoreShown, setReadMoreShown, limit, children, openLink }) => {

    const toggleBtn = () => {
        setReadMoreShown(!isReadMoreShown)
    }

    return (
        <div>
            <span onClick={openLink}>{isReadMoreShown ? children : children.substr(0, limit)}</span>
            <Button onClick={toggleBtn}>{isReadMoreShown ? 'Read Less' : '...Read More'}</Button>
        </div>
    );
}

export function PostPreview2({ index, page, data, dataLimit, spacename, user, username, fullname, profileUrl, authenticated, loggedIn, layoutOnComment, setLayoutOnComment, resetButtonid, commentViewid, onFeed }) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const dispatch = useDispatch();
    const { id, duid, title, spaceid, space, post, hashtags, link1, link2, link3, link4, createdat, updatedat, createdby, balance, timeago, urlparam, images, reported, lumens, numcomments, path, edited, viewtimes } = data;
    const [isReadMoreShownTitle, setReadMoreShownTitle] = useState(false)
    const [isReadMoreShownPost, setReadMoreShownPost] = useState(false)

    const ds = useSelector(state => state.discussion.discussionList);
    const [discussionList, setDiscussionList] = useState(ds);
    const [thePost, setThePost] = useState(discussionList && discussionList.discussions ? discussionList.discussions[(dataLimit * (page - 1)) + index] : null)

    const [deletePost, setDeletePost] = useState(discussionList && discussionList.discussions ? discussionList.discussions[(dataLimit * (page - 1)) + index] : null)
    const [editPost, setEditPost] = useState(discussionList && discussionList.discussions ? discussionList.discussions[(dataLimit * (page - 1)) + index] : null)
    const [inputText, setInputText] = useState(post);
    const [numField, setNumField] = useState(
        link1 && link2 && link3 && link4 ?
            4 : ((link1 && link2 && link3 ?
                3 : ((link1 && link2) ?
                    2 : 1)))
    )
    const [layoutPreview, setLayoutPreview] = useState(1)
    const [imageFiles, setImageFiles] = useState(images) // Only for Preview

    const [characterLimit] = useState(2000);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(true)

    const d = useSelector((state) => state.discussion.done);
    const [donea, setDonea] = useState(d)
    const e = useSelector((state) => state.discussion.error);
    const [errora, setErrora] = useState(e);
    const [sending, setSending] = useState(false);

    const da = useSelector((state) => state.appreciation.done);
    const [done, setDone] = useState(da)
    const ea = useSelector((state) => state.appreciation.error);
    const [error, setError] = useState(ea);
    const [giving, setGiving] = useState(false);

    const dc = useSelector(state => state.comment.done);
    const [donec, setDonec] = useState(dc);

    const [onLumen, setOnLumen] = useState(false)
    const [onNun, setOnNun] = useState(false)
    const [lumenStyle, setLumenStyle] = useState({ color: '#fff' })
    const [nunStyle, setNunStyle] = useState({ color: "#fff", transform: 'rotate(180deg)' })
    const [shareStyle, setShareStyle] = useState({ color: "#fff", transform: 'scaleX(-1)' })

    const [uploadLimit] = useState(10)
    const [uploadPreviewLimit] = useState(100)
    const inputUpload = "inputUpload";

    const [entry, setEntry] = useState(true)

    const [actualLumens, setActualLumens] = useState(lumens)

    const s = useSelector(state => state.space.spaceList);
    const [spaceList, setSpaceList] = useState(s);
    const ss = useSelector(state => state.spaceswitch);
    const [spaceswitch, setSpaceSwitch] = useState(ss);
    const lop = useSelector(state => state.listonespaceswitch);
    const [spaceNameForFeed, setSpaceNameForFeed] = useState("")

    const [numberOfComments, setNumberOfComments] = useState(numcomments)

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const handleOpenDeletePopup = (e) => {
        setOpenDeletePopup(true);
    }
    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const [openShowProfilePopup, setOpenShowProfilePopup] = useState(false);
    const handleOpenShowProfilePopup = (e) => {
        setOpenShowProfilePopup(true);
    }

    const handleCloseShowProfilePopup = () => {
        setOpenShowProfilePopup(false);
    }

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }

    const [openOutOfScopePopup, setOpenOutOfScopePopup] = useState(false);
    const handleOpenOutOfScopePopup = (e) => {
        setOpenOutOfScopePopup(true);
    }
    const handleCloseOutOfScopePopup = () => {
        setOpenOutOfScopePopup(false);
    }

    useEffect(() => {
        setInputText(inputText)
    }, [inputText])

    useEffect(() => {
        setSpaceList(s)
    }, [s])

    useEffect(() => {
        setActualLumens(lumens)
    }, [lumens])

    useEffect(() => {
        setNumberOfComments(numcomments)
    }, [numcomments])

    // useEffect(() => {
    //     setOpenDeletePopup(openDeletePopup);
    // }, [openDeletePopup])

    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (sending) {
            if (d) {
                if (e !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    // setShowSpinner(false);
                    setSending(false)

                    if (onFeed) {
                        dispatch(discussionActions.getFeedFilter())
                        dispatch(discussionActions.getFeed());
                        dispatch(appreciationActions.myAppreciation())
                    } else {
                        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                            if (ss == spaceswitchConstants.FEED_NR) {
                                dispatch(discussionActions.getFeedFilter())
                                dispatch(discussionActions.getFeed());
                            }
                            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                dispatch(spaceActions.getSpaceList());
                            }
                            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                dispatch(discussionActions.getMyDiscussionList());
                            }
                            if (ss == spaceswitchConstants.LIGHT_NR) {
                                dispatch(discussionActions.getLightDiscussionList());
                            }
                        }

                        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                dispatch(discussionActions.getDiscussionList(urlparam))
                            }
                            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                dispatch(discussionActions.getMyDiscussionList());
                            }
                            if (ss == spaceswitchConstants.LIGHT_NR) {
                                dispatch(discussionActions.getLightDiscussionList());
                            }
                        }

                        dispatch(appreciationActions.myAppreciation())

                        Toast.fire({
                            icon: "error",
                            title: "An error has occurred. Please try again ..."
                        });
                    }
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    // setShowSpinner(false);
                    setSending(false)

                    if (onFeed) {
                        dispatch(discussionActions.getFeedFilter())
                        dispatch(discussionActions.getFeed());
                        dispatch(appreciationActions.myAppreciation())
                    } else {
                        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                            if (ss == spaceswitchConstants.FEED_NR) {
                                dispatch(discussionActions.getFeedFilter())
                                dispatch(discussionActions.getFeed());
                            }
                            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                dispatch(spaceActions.getSpaceList());
                            }
                            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                dispatch(discussionActions.getMyDiscussionList());
                            }
                            if (ss == spaceswitchConstants.LIGHT_NR) {
                                dispatch(discussionActions.getLightDiscussionList());
                            }
                        }

                        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                                dispatch(discussionActions.getDiscussionList(urlparam))
                            }
                            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                                dispatch(discussionActions.getMyDiscussionList());
                            }
                            if (ss == spaceswitchConstants.LIGHT_NR) {
                                dispatch(discussionActions.getLightDiscussionList());
                            }
                        }

                        dispatch(appreciationActions.myAppreciation())

                        Toast.fire({
                            icon: "success",
                            title: "Successfully deleted"
                        });
                    }
                }
            }
        }
    }, [d]);

    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (giving) {
            if (da) {
                if (ea !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    // setShowSpinner(false);
                    // setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "error",
                    //     title: "An error has occurred. Please try again ..."
                    // });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    // setShowSpinner(false);
                    // setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "success",
                    //     title: "Successfully deleted"
                    // });
                }
            }
        }
    }, [da]);

    const handleSubmit = (e) => {
        // For deleting
        e.preventDefault();

        var discussion = new DiscussionClass();
        discussion.id = deletePost.id;
        discussion.duid = deletePost.duid;
        discussion.title = deletePost.title;
        discussion.post = deletePost.post;
        discussion.hashtags = deletePost.hashtags;
        discussion.link1 = deletePost.link1;
        discussion.link2 = deletePost.link2;
        discussion.link3 = deletePost.link3;
        discussion.link4 = deletePost.link4;
        discussion.createdat = deletePost.createdat;
        discussion.updatedat = deletePost.updatedat;
        discussion.createdby = deletePost.createdby;
        discussion.spacename = deletePost.space;
        discussion.space = deletePost.spaceid;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var discussionInfos = JSON.parse(JSON.stringify(discussion, replacer));

        setSending(true)
        setOpenDeletePopup(false);
        dispatch(discussionActions.deleteDiscussion(discussionInfos));
    }

    // dispatch(discussionActions.getDiscussionList(spacename))

    useEffect(() => {
        setImageFiles(images)
    }, [images])

    // useEffect(() => {
    //     setEditPost(editPost)
    // }, [editPost])

    // useEffect(() => {
    //     setEditMode(editMode)
    // }, [editMode])

    // useEffect(() => {
    //     setReadMoreShown(isReadMoreShown)
    // }, [isReadMoreShown])

    // useEffect(() => {
    //     setLayoutPreview(layoutPreview)
    // }, [layoutPreview])

    // useEffect(() => {
    //     setNumField(numField)
    // }, [numField])

    // event handler
    const handleChange = event => {
        setInputText(event.target.value);
    };

    // console.log(link1)
    // console.log(link2)
    // console.log(link3)
    // console.log(link4)

    var itemPlace1 = (link1 && isOnlyWhitespace(link2) && isOnlyWhitespace(link3) && isOnlyWhitespace(link4))
    var itemPlace2 = (link2 && isOnlyWhitespace(link1) && isOnlyWhitespace(link3) && isOnlyWhitespace(link4))
    var itemPlace3 = (link3 && isOnlyWhitespace(link1) && isOnlyWhitespace(link2) && isOnlyWhitespace(link4))
    var itemPlace4 = (link4 && isOnlyWhitespace(link1) && isOnlyWhitespace(link2) && isOnlyWhitespace(link3))

    var weblink1 = validationUrl(link1) ? "\"" + link1 + "\"" : "";
    var domain1 = validationUrl(link1) ? extractDomain(link1) : "";

    var weblink2 = validationUrl(link2) ? "\"" + link2 + "\"" : "";
    var domain2 = validationUrl(link2) ? extractDomain(link2) : "";

    var weblink3 = validationUrl(link3) ? "\"" + link3 + "\"" : "";
    var domain3 = validationUrl(link3) ? extractDomain(link3) : "";

    var weblink4 = validationUrl(link4) ? "\"" + link4 + "\"" : "";
    var domain4 = validationUrl(link4) ? extractDomain(link4) : "";

    function validationUrl(link) {
        return link && isValidUrl(link) && !isOnlyWhitespace(link)
    }

    useEffect(() => {
        // console.log("balance" + balance)
        if (balance) {
            if (entry) {
                if (balance.lumen) {
                    setOnLumen(true)
                    setOnNun(false)
                    setLumenStyle({ color: '#ffff00' })
                } else if (balance.nun) {
                    setOnNun(true)
                    setOnLumen(false)
                    setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                }

                if (balance.star) {
                    setOnStar(true)
                    setStarStyle({ color: '#ffff00' })
                    // console.log("sssssssssssttttttttttttttaaaaaaaaaaaaaaaarrrrrrrrrrrrrrrrrrrr")
                }
            }
            setEntry(false)
        }
    }, [])

    useEffect(() => {
        if (layoutPreview == 3) {
            setLayoutOnComment($('#' + resetButtonid))
        }
    }, [layoutPreview])

    const switchToComment = (e) => {
        if (layoutOnComment && layoutOnComment.id !== e.target.id) {
            // if (client && client.connected) {
            //     client.deactivate();
            // }
            dispatch({ type: commentConstants.GET_REQUEST });
            setTimeout(1000)
            layoutOnComment.click()
        } else {
            dispatch({ type: commentConstants.GET_REQUEST });
        }

        setLayoutPreview(3)
    }

    const setBalanceOnLumen = (e) => {
        if (authenticated) {
            if ((!onLumen && !onNun)) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.resetLumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Switch
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnNun = (e) => {
        if (authenticated) {
            // Normal
            if ((!onNun && !onLumen)) {
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Reset
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.resetNun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                if (actualLumens > 0) {
                    setActualLumens((prev) => prev + 1)
                }
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                // Switch
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.discussion = thePost.duid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnStar = (e) => {
        if (authenticated) {
            if (!onStar) {
                setStarStyle({ color: '#fff' })
                setStarStyle({ color: '#ffff00' })

                var giving = new GivingClass()
                giving.user = createdby.uuid;
                giving.star = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(true)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else {
                setStarStyle({ color: '#ffff00' })
                setStarStyle({ color: '#fff' })

                var giving = new GivingClass()
                giving.user = createdby.uuid;
                giving.resetStar = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(false)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    return (<>
        {layoutPreview == 1 ?
            <PreviewFirst
                spaceid={spaceid}
                space={space}
                urlparam={urlparam}
                handleCloseShowProfilePopup={handleCloseShowProfilePopup}
                authenticated={authenticated}
                user={user}
                createdby={createdby}
                layoutPreview={layoutPreview}
                setLayoutPreview={setLayoutPreview}
                openInfoPopup={openInfoPopup}
                handleCloseInfoPopup={handleCloseInfoPopup}
                handleOpenInfoPopup={handleOpenInfoPopup}
                handleOpenSignInPopup={handleOpenSignInPopup}
                handleOpenDeletePopup={handleOpenDeletePopup}
                handleOpenOutOfScopePopup={handleOpenOutOfScopePopup}
                openOutOfScopePopup={openOutOfScopePopup}
                handleCloseOutOfScopePopup={handleCloseOutOfScopePopup}
                openShowProfilePopup={openShowProfilePopup}
                handleOpenShowProfilePopup={handleOpenShowProfilePopup}
                openSignInPopup={openSignInPopup}
                handleCloseSignInPopup={handleCloseSignInPopup}
                openDeletePopup={openDeletePopup}
                handleCloseDeletePopup={handleCloseDeletePopup}
                handleSubmit={handleSubmit}
                isReadMoreShownTitle={isReadMoreShownTitle}
                setReadMoreShownTitle={setReadMoreShownTitle}
                isReadMoreShownPost={isReadMoreShownPost}
                setReadMoreShownPost={setReadMoreShownPost}
                duid={duid}
                title={title}
                post={post}
                fullname={fullname}
                profileUrl={profileUrl}
                hashtags={hashtags}
                link1={link1}
                link2={link2}
                link3={link3}
                link4={link4}
                itemPlace1={itemPlace1}
                itemPlace2={itemPlace2}
                itemPlace3={itemPlace3}
                itemPlace4={itemPlace4}
                weblink1={weblink1}
                weblink2={weblink2}
                weblink3={weblink3}
                weblink4={weblink4}
                domain1={domain1}
                domain2={domain2}
                domain3={domain3}
                domain4={domain4}
                timeago={timeago}
                images={images}
                balance={balance}
                path={path}
                setBalanceOnLumen={setBalanceOnLumen}
                setBalanceOnNun={setBalanceOnNun}
                setBalanceOnStar={setBalanceOnStar}
                lumenStyle={lumenStyle}
                nunStyle={nunStyle}
                starStyle={starStyle}
                shareStyle={shareStyle}
                switchToComment={switchToComment}
                imageFiles={imageFiles}
                setImageFiles={setImageFiles}
                reported={reported}
                lumens={actualLumens}
                numcomments={numcomments} // fixed
                edited={edited}
                numberOfComments={numberOfComments}
                setNumberOfComments={setNumberOfComments}
                viewtimes={viewtimes}
                outofscopespace={lop === listonespaceswitchConstants.ONE_SPACE_NR && ss === spaceswitchConstants.ALL_SPACES_NR}
            /> : null}

        {layoutPreview == 2 ? <Zoom in={layoutPreview == 2} style={{ transitionDelay: layoutPreview == 2 ? '500ms' : '0ms' }}>
            <CreatePost
                key={index}
                index={index}
                name={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR ? spaceNameForFeed : space}
                post={editPost}
                // setPost={setEditPost}
                inputText={inputText}
                // setInputText={setInputText}
                numField={numField}
                // setNumField={setNumField}
                characterLimit={characterLimit}
                spacename={spacename}
                layoutPreview={layoutPreview}
                setLayoutPreview={setLayoutPreview}
                editMode={editMode}
                user={user}
                username={username}
                fullname={fullname}
                profileUrl={profileUrl}
                authenticated={authenticated}
                loggedIn={loggedIn}
                images={imageFiles}
                // setImages={setImageFiles}
                uploadLimit={uploadLimit}
                uploadPreviewLimit={uploadPreviewLimit}
                inputUpload={inputUpload}
                spaceid={spaceid}
                spaceList={spaceList}
                onFeed={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR}
                setSpaceNameForFeed={setSpaceNameForFeed}
            />
        </Zoom> : null}

        {layoutPreview == 3 ? <Comment
            authenticated={authenticated}
            duid={duid}
            space={space}
            setLayoutPreview={setLayoutPreview}
            thePost={thePost}
            resetButtonid={resetButtonid}
            handleOpenSignInPopup={handleOpenSignInPopup}
            openSignInPopup={openSignInPopup}
            handleCloseSignInPopup={handleCloseSignInPopup}
            handleOpenDeletePopup={handleOpenDeletePopup}
            commentViewid={commentViewid}
            user={user}
            starStyle={starStyle}
            setBalanceOnStar={setBalanceOnStar}
            setBalanceOnLumen={setBalanceOnLumen}
            setBalanceOnNun={setBalanceOnNun}
            lumenStyle={lumenStyle}
            nunStyle={nunStyle}
            urlparam={urlparam}
            lumens={lumens}
            numcomments={numcomments}
            numberOfComments={numberOfComments}
            setNumberOfComments={setNumberOfComments}
        /> : null}
    </>);
}

const PreviewFirst = ({
    urlparam,
    space,
    authenticated,
    user,
    createdby,
    layoutPreview,
    setLayoutPreview,
    openInfoPopup,
    handleCloseInfoPopup,
    handleOpenInfoPopup,
    handleOpenSignInPopup,
    handleOpenDeletePopup,
    handleOpenOutOfScopePopup,
    openOutOfScopePopup,
    handleCloseOutOfScopePopup,
    openShowProfilePopup,
    handleOpenShowProfilePopup,
    handleCloseShowProfilePopup,
    openSignInPopup,
    handleCloseSignInPopup,
    openDeletePopup,
    handleCloseDeletePopup,
    handleSubmit,
    isReadMoreShownTitle,
    setReadMoreShownTitle,
    isReadMoreShownPost,
    setReadMoreShownPost,
    duid,
    title,
    post,
    fullname,
    profileUrl,
    hashtags,
    link1, link2, link3, link4,
    itemPlace1, itemPlace2, itemPlace3, itemPlace4,
    weblink1, weblink2, weblink3, weblink4,
    domain1, domain2, domain3, domain4,
    timeago,
    balance,
    path,
    images,
    setBalanceOnLumen,
    setBalanceOnNun,
    setBalanceOnStar,
    lumenStyle,
    nunStyle,
    starStyle,
    shareStyle,
    switchToComment,
    imageFiles,
    setImageFiles,
    reported,
    lumens,
    numcomments,
    edited,
    numberOfComments,
    setNumberOfComments,
    outofscopespace,
    viewtimes
}) => {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openShowReportPopup, setOpenShowReportPopup] = useState(false);
    const handleOpenReportPopup = (e) => {
        setOpenShowReportPopup(true);
    }
    const handleCloseShowReportPopup = () => {
        setOpenShowReportPopup(false);
    }

    const e = useSelector(state => state.report.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.report.done);
    const [done, setDone] = useState(d);

    const [reportStyle, setReportStyle] = useState({ color: '#fff' })
    const [reportedUser, setReportedUser] = useState(reported)
    const [reporting, setReporting] = useState(false)

    const [issue, setIssue] = useState("")

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const dos = useSelector(state => state.outofscope.done);
    const [outscoping, setOutScoping] = useState(false)

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const report = () => {
        handleOpenReportPopup()
    }

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            setIssue(e.target.value);
        }
    }

    const handleReportSubmit = () => {
        if (authenticated) {
            if (!reportedUser) {
                if (issue) {
                    var report = new ReportClass()
                    report.duid = duid
                    report.issue = issue
                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var reportInfos = JSON.parse(JSON.stringify(report, replacer));

                    setReporting(true)
                    setReportedUser(true)
                    setReportStyle({ color: '#ff0000' })

                    handleCloseShowReportPopup()
                    dispatch(reportActions.submitReport(reportInfos))
                } else {
                    Toast.fire({
                        icon: "warning",
                        title: "Select one issue about the report"
                    });
                }
            } else {
                Toast.fire({
                    icon: "warning",
                    title: "You have already reported"
                });
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const handleOutOfScope = () => {
        if (authenticated) {
            if (duid) {
                var outofscope = new OutOfScopeClass()
                outofscope.duid = duid
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var outofscopeInfos = JSON.parse(JSON.stringify(outofscope, replacer));

                setOutScoping(true)

                dispatch(outofscopeActions.moveToOutOfScope(outofscopeInfos))
                handleCloseOutOfScopePopup()
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        if (reportedUser) {
            setReportedUser(true)
            setReportStyle({ color: '#ff0000' })
        } else {
            setReportedUser(false)
            setReportStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (reporting) {
            if (d) {
                if (e !== undefined) {
                    setReporting(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setReporting(false)
                    Toast.fire({
                        icon: "success",
                        title: "Thank you for Reporting !"
                    });
                }
            }
        }
    }, [d]);

    useEffect(() => {
        if (outscoping) {
            if (dos) {
                if (e !== undefined) {
                    setOutScoping(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setOutScoping(false)

                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed());
                    dispatch(appreciationActions.myAppreciation())

                    Toast.fire({
                        icon: "success",
                        title: 'This Content has moved to "Out of Scope"'
                    });
                }
            }
        }
    }, [dos]);

    const [iteration, setIteration] = useState(parseTimeAgoToTimestamp(timeago));
    const [timestamp, setTimestamp] = useState("")

    useEffect(() => {
        if (iteration <= 10800) {
            const intervalId = setInterval(() => {
                setIteration(iteration + 1);
                const seconds = iteration;

                if (seconds < 60) setTimestamp(seconds + " s");
                else if (seconds < 3600) setTimestamp(`${Math.floor(seconds / 60)} min`);
                else if (seconds < 86400) setTimestamp(`${Math.floor(seconds / 3600)} h`);
                else if (seconds < 2592000) setTimestamp(`${Math.floor(seconds / 86400)} d`);
                else if (seconds < 31536000) setTimestamp(`${Math.floor(seconds / 2592000)} M`);
                else return setTimestamp(`${Math.floor(seconds / 31536000)} y`);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setTimestamp(roundTimeAgo(timeago))
        }
    }, [iteration]);

    const spacepath = "/space/" + urlparam;

    function openOneSpace() {
        navigate(spacepath)
        resetPage()
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    useEffect(() => {
        setIteration(parseTimeAgoToTimestamp(timeago));
    }, [parseTimeAgoToTimestamp(timeago)])

    return (<Container>
        <div style={{ display: 'block' }}>
            <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                <a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none", cursor: "pointer" }} onClick={openOneSpace}>{space} <LogoSpace name={space} color={"#fff"} /></a>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.5rem' }}>
                    {authenticated && !outofscopespace &&
                        ((user && user.role && user.role.includes("MODERATOR")) ||
                            (user && user.role && user.role.includes("ADMIN"))) ?
                        <NumbersSharpIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={(e) => handleOpenOutOfScopePopup()} />
                        : null}

                    {authenticated &&
                        ((user && createdby && user.uuid === createdby.uuid) ||
                            (user && user.role && user.role.includes("MODERATOR")) ||
                            (user && user.role && user.role.includes("ADMIN"))) ?
                        <EditIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={e => setLayoutPreview(2)} />
                        : null}

                    <InfoIcon className="notDraggable text-white" onClick={e => { handleOpenInfoPopup() }} style={{ cursor: "pointer" }} />

                    {authenticated &&
                        ((user && createdby && user.uuid === createdby.uuid) ||
                            (user && user.role && user.role.includes("MODERATOR")) ||
                            (user && user.role && user.role.includes("ADMIN"))) ?
                        <DeleteIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={(e) => handleOpenDeletePopup()} />
                        : null}
                </div>
            </h6>
            <Card sx={styleMobLarge2}>
                <DraggableReportPopup openShowReportPopup={openShowReportPopup} handleCloseShowReportPopup={handleCloseShowReportPopup} handleReportSubmit={handleReportSubmit} handleChange={handleChange} />
                <DraggableShowProfilePopup openShowProfilePopup={openShowProfilePopup} handleCloseShowProfilePopup={handleCloseShowProfilePopup} balance={balance} createdby={createdby} setBalanceOnStar={setBalanceOnStar} starStyle={starStyle} showStar={true} />
                <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />
                <DraggableDeletePopup openDeletePopup={openDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} handleSubmit={handleSubmit} />

                <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"news"} />

                <DraggableOutOfScopePopup openOutOfScopePopup={openOutOfScopePopup} handleCloseOutOfScopePopup={handleCloseOutOfScopePopup} handleSubmit={handleOutOfScope} />

                <div style={{ height: 'auto', width: '100%' }}>

                    <div style={{ margin: 15 }}>
                        <Typography gutterBottom variant="h6" component="div" style={{ color: theme === "dark" ? '#fff' : '#000' }}>
                            {title.length > 120 ?
                                <ReadMoreReadLess limit={120} isReadMoreShown={isReadMoreShownTitle} setReadMoreShown={setReadMoreShownTitle}>
                                    {title}
                                </ReadMoreReadLess> :
                                title
                            }
                        </Typography>
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        style={{ display: 'flex' }}
                        p={0}
                    >
                        <Item style={{ padding: 0 }}>
                            {timestamp ?
                                <div class="bg-primary bg-gradient text-white" style={{ padding: '2px 5px 2px 5px', borderRadius: 5, display: 'inline-block', marginLeft: 15 }}><b>{timestamp}</b></div>
                                : null}
                        </Item>
                        <Item style={{ padding: 0 }}>
                            {edited ?
                                <div class="bg-primary bg-gradient text-white" style={{ padding: '2px 5px 2px 5px', borderRadius: 5, display: 'inline-block', marginLeft: 15 }} title={"Edited"}><EditIcon /></div>
                                : null}
                        </Item>
                    </Stack>


                    {imageFiles && imageFiles.length > 0 && <ImageBundle files={imageFiles} setFiles={setImageFiles} postpreview2={layoutPreview == 1} />}

                    <div style={{ margin: '15px 15px 0px 15px' }}>
                        <Typography gutterBottom variant="body1" component="div" style={{ whiteSpace: 'pre-line', color: theme === "dark" ? '#fff' : '#000' }}>
                            {post.length > 300 ?
                                <ReadMoreReadLess limit={300} isReadMoreShown={isReadMoreShownPost} setReadMoreShown={setReadMoreShownPost}>
                                    {post}
                                </ReadMoreReadLess> :
                                post
                            }
                        </Typography>
                        <div style={{ height: 15, marginLeft: 15, marginBottom: 10 }}>
                            <span> </span>
                            <span style={{ color: theme === 'dark' ? "#fff" : "#fff", float: "right" }}>
                                <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(viewtimes)}</span><PollIcon /></Box>
                            </span>
                        </div>
                    </div>
                    {authenticated && (user && createdby && user.uuid === createdby.uuid) && (!isOnlyWhitespace(title) || !isOnlyWhitespace(post)) ? <>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            style={{ display: 'flex' }}
                            p={2}
                        >
                            <Item style={{ padding: 0, cursor: 'pointer' }} onClick={handleOpenShowProfilePopup}><Avatar alt={fullname} src={profileUrl} /></Item>
                            <Item style={{ cursor: 'pointer', color: theme === "dark" ? '#fff' : '#000' }} onClick={handleOpenShowProfilePopup}>{truncateFullName(fullname)}</Item>
                        </Stack>
                    </> : (createdby && (!isOnlyWhitespace(title) || !isOnlyWhitespace(post))) ? <>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            style={{ display: 'flex' }}
                            p={2}
                        >
                            <Item style={{ padding: 0, cursor: 'pointer' }} onClick={handleOpenShowProfilePopup}><Avatar alt={createdby.displayname ? createdby.displayname : (createdby.name ? createdby.firstname + " " + createdby.name : createdby.firstname)} src={createdby.pictureUrl ? createdby.pictureUrl : "/avatar2.png"} /></Item>
                            <Item style={{ cursor: 'pointer', color: theme === "dark" ? '#fff' : '#000' }} onClick={handleOpenShowProfilePopup}>{createdby.displayname ? truncateFullName(createdby.displayname) : (createdby.name ? truncateFullName(createdby.firstname + " " + createdby.name) : truncateFullName(createdby.firstname))}</Item>
                        </Stack>
                    </> : null}

                    {(link1 && !isOnlyWhitespace(link1)) || (link2 && !isOnlyWhitespace(link2)) || (link3 && !isOnlyWhitespace(link3)) || (link4 && !isOnlyWhitespace(link4)) ?
                        <Box sx={{ textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left', xl: 'left' } }}>
                            <div style={{ height: 20, margin: '0px 15px 15px 15px' }}>
                                <Typography gutterBottom variant="body2" component="div" style={{ color: theme === "dark" ? '#fff' : '#000' }} sx={{ textAlign: { sm: 'center', md: 'center', lg: 'center', xl: 'center' } }}>
                                    More on:
                                </Typography>
                            </div>
                            <div style={{ height: 'auto', margin: 15 }}>
                                <Typography gutterBottom variant="body2" component="div" style={{ color: theme === "dark" ? '#fff' : '#000' }} sx={{ textAlign: { sm: 'center', md: 'center', lg: 'center', xl: 'center' } }}>
                                    <Grid
                                        container
                                        // spacing={4}
                                        // className="marginLaptop"
                                        justifyItems="left"
                                    // style={{ PaddingRight: "1000px" }}
                                    >
                                        {link1 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <p>{Parser(`<a href=${weblink1} target='_blank'>${domain1}</a>`)}</p>
                                        </Grid> : null}
                                        {link2 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <p>{Parser(`<a href=${weblink2} target='_blank'>${domain2}</a>`)}</p>
                                        </Grid> : null}
                                        {link3 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <p>{Parser(`<a href=${weblink3} target='_blank'>${domain3}</a>`)}</p>
                                        </Grid> : null}
                                        {link4 ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <p>{Parser(`<a href=${weblink4} target='_blank'>${domain4}</a>`)}</p>
                                        </Grid> : null}
                                    </Grid>
                                </Typography>
                            </div>
                        </Box> : null}
                    <div style={{ height: 'auto', margin: '0px 15px 0px 15px' }}>
                        <Typography gutterBottom variant="body2" component="div" style={{ color: theme === "dark" ? '#fff' : '#000' }}>
                            {hashtags}
                        </Typography>
                    </div>
                </div>
                <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    p={1}
                >
                    <Item>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                        >
                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={(e) => switchToComment(e)}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(numberOfComments)}</span><CommentIcon style={{ color: "#FFF" }} /></Box>
                                </Button>
                            </Item>
                            <Item style={{ paddingLeft: 0 }}>
                                <Share shareStyle={shareStyle} title={title} post={post} path={path} preUrl={preUrlDiscussion} />
                            </Item>
                        </Stack>

                        {/* <Button size="small" variant="contained" style={{ borderRadius: 20, padding: 5, fontWeight: 'bold', marginLeft: 8 }}>Comment</Button> */}
                    </Item>
                    <Item style={{ paddingLeft: 0 }}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}
                        >
                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnLumen}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(lumens)}</span><TipsAndUpdatesIcon style={lumenStyle} /></Box>
                                    {/* <TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /> */}
                                </Button>
                            </Item>
                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnNun}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><TipsAndUpdatesIcon style={nunStyle} /></Box>
                                    {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                                </Button>
                            </Item>
                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={report}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><FlagIcon style={reportStyle} /></Box>
                                </Button>
                            </Item>
                        </Stack>
                    </Item>
                </Stack>
            </Card>
        </div>
    </Container>)
}

export function Share({ shareStyle, title, post, path, preUrl, profilsharing, handleOpenShowWarningProfil }) {
    var url = preUrl + path

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => {

                const copyLink = () => {
                    if (profilsharing) {
                        if (!path) {
                            handleOpenShowWarningProfil()
                            popupState.close()
                            return;
                        }
                    }
                    navigator.clipboard.writeText(url)
                    popupState.close()
                }

                const otherOptions = () => {
                    if (profilsharing) {
                        if (!path) {
                            handleOpenShowWarningProfil()
                            popupState.close()
                            return;
                        }
                    }

                    if (navigator.share) {
                        navigator.share({
                            title: title,
                            text: post,
                            url: url,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    }
                    popupState.close()
                }


                return (
                    <React.Fragment>
                        <Button title={"Share"} size="small" variant="contained" {...bindTrigger(popupState)} style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }}>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><ReplyIcon style={shareStyle} /></Box>
                            {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                        </Button>
                        <Menu
                            {...bindMenu(popupState)}
                            style={{ zIndex: 10000000010 }}
                        >
                            <MenuItem onClick={copyLink}><ContentCopyIcon style={{ marginRight: 5 }} />Copy Link</MenuItem>
                            <MenuItem onClick={otherOptions}><ShareIcon style={{ marginRight: 5 }} />Other Options</MenuItem>
                            {/* <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
                        </Menu>
                    </React.Fragment>
                );
            }}
        </PopupState>
    );
}

export function Share2({ shareStyle, title, post, path, preUrl, profilsharing, handleOpenShowWarningProfil }) {
    var url = preUrl + path

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const copyLink = () => {
        if (profilsharing) {
            if (!path) {
                handleOpenShowWarningProfil()
                setAnchorEl(null);
                return;
            }
        }
        navigator.clipboard.writeText(url)
        setAnchorEl(null);
    }

    const otherOptions = () => {
        if (profilsharing) {
            if (!path) {
                handleOpenShowWarningProfil()
                setAnchorEl(null);
                return;
            }
        }

        if (navigator.share) {
            navigator.share({
                title: title,
                text: post,
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                id="share"
                size="small"
                variant="contained"
                style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><ReplyIcon style={shareStyle} /></Box>
            </Button>
            <Menu
                id="share-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{ zIndex: 10000000010 }}
            >
                <MenuItem onClick={copyLink}><ContentCopyIcon style={{ marginRight: 5 }} />Copy Link</MenuItem>
                <MenuItem onClick={otherOptions}><ShareIcon style={{ marginRight: 5 }} />Other Options</MenuItem>
            </Menu>
        </div>
    );
}





export function Comment({
    authenticated,
    duid,
    space,
    setLayoutPreview,
    thePost,
    resetButtonid,
    openSignInPopup,
    handleOpenSignInPopup,
    handleCloseSignInPopup,
    handleOpenDeletePopup,
    commentViewid,
    user,
    setBalanceOnStar,
    starStyle,
    setBalanceOnLumen,
    setBalanceOnNun,
    lumenStyle,
    nunStyle,
    urlparam,
    lumens,
    numcomments,
    numberOfComments,
    setNumberOfComments
}) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const useStylesForComment = makeStyles((thm) =>
        createStyles({
            paper: {
                width: "80vw",
                height: "80vh",
                /*maxWidth: "500px",*/
                maxHeight: "700px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9"
            },
            paper2: {
                width: "80vw",
                maxWidth: "500px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative"
            },
            container: {
                /*width: "100vw",
                height: "100vh",*/
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            messagesBody: {
                width: "calc( 100% - 20px )",
                margin: 10,
                overflowY: "scroll",
                height: "calc( 100% - 80px )",
                backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9",
                boxShadow: 'none',
            }
        })
    );

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStylesForComment();
    const c = useSelector(state => state.comment.comments?.comments);
    const [commentList, setCommentList] = useState(c)
    const [addingComment, setAddingComment] = useState(false)
    const [entry, setEntry] = useState(true)

    const e = useSelector(state => state.comment.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.comment.done);
    const [done, setDone] = useState(d);

    const ms = useSelector(state => state.menuswitch);
    const [menuSwitch, setMenuSwitch] = useState(ms);

    // const [inputText, setInputText] = useState("");
    const [open, setOpen] = useState(false)
    const [showBlank, setShowBlank] = useState(false)

    const [isTyping, setIsTyping] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(true)
    // let typingTimeoutId = null;

    const commentRef = useRef(null);

    const [otherUsersTyping, setOtherUsersTyping] = useState(new Set());
    const typingTimeoutId = useRef(null);
    const [subscription, setSubscription] = useState(null)

    const [openEditmode, setOpenEditmode] = useState(false)
    const [commentForEdit, setCommentForEdit] = useState(null)
    const [actualEditButton, setActualEditButton] = useState(null)

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }

    client.onConnect = () => {
        var sub = client.subscribe('/topic/' + urlparam + "/" + thePost?.duid, message => {// OnReceived
            if (message.body) {
                // alert('got message with body ' + message.body);
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                // console.log(`Received: ${message.body}`)
                try {
                    var comment = JSON.parse(message.body, replacer);
                    if (comment.adding) {
                        if (comment.text) {
                            // console.log(comment.text)
                            if (!comment.suspended && !comment.banned && !comment.deleted) {
                                setOtherUsersTyping((prev) => {
                                    const updated = new Set(prev);
                                    if ((user) && (user.uuid != comment.createdby.uuid)) {
                                        updated.delete(comment.createdby.uuid);
                                    } else if (!user) {
                                        updated.delete(comment.createdby.uuid);
                                    }
                                    return updated;
                                });

                                //console.log("push comment to view")

                                // setInputText("")
                                setOpen(false)

                                // setAddingComment(true)
                                //console.log("Before : " + commentList)
                                // commentList.push(comment)
                                setCommentList((comments) => {
                                    const updated = comments;

                                    updated.push(comment)

                                    return updated;
                                });

                                setNumberOfComments((prev) => prev + 1)

                                // setCommentList(comments => [...comments, comment]);

                                // Toast.fire({
                                //     icon: "success",
                                //     title: "You have just commented"
                                // });

                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else {
                                if (user.uuid == comment.createdby.uuid) {
                                    if (comment.suspended || comment.banned) {
                                        Toast.fire({
                                            icon: "warning",
                                            title: "Sorry, your sharing, commenting, and messaging are temporarily restricted."
                                        });
                                        return;
                                    } else if (comment.deleted) {
                                        Toast.fire({
                                            icon: "warning",
                                            title: "Sorry, this discussion is deleted, you can't comment anymore"
                                        });
                                    }
                                }
                            }
                            //console.log("After : " + commentList)
                        }
                    } else if (comment.editing) {
                        if (comment.text) {
                            if (!comment.suspended && !comment.banned && !comment.deleted) {

                                // setInputText("")
                                setCommentForEdit(null)
                                setOpenEditmode(false)

                                setCommentList((comments) => {
                                    const updated = comments.map(item => {
                                        if (item.cuid === comment.cuid) {
                                            return { ...item, text: comment.text };
                                        }
                                        return item;
                                    });

                                    return updated;
                                });

                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else {
                                if (user.uuid == comment.createdby.uuid) {
                                    if (comment.suspended || comment.banned) {
                                        Toast.fire({
                                            icon: "warning",
                                            title: "Sorry, your sharing, commenting, and messaging are temporarily restricted."
                                        });
                                        return;
                                    } else if (comment.deleted) {
                                        Toast.fire({
                                            icon: "warning",
                                            title: "Sorry, this discussion is deleted, you can't comment anymore"
                                        });
                                    }
                                }
                            }
                        }
                    } else if (comment.deleting) {
                        // console.log("deleting comment2")
                        // console.log(comment)

                        // $('#' + comment.commentid).remove();
                        setCommentList((comments) => {
                            var updated = comments;

                            updated = updated.filter((item) => {
                                return item.cuid != comment.cuid;
                            })

                            return updated
                        });

                        setNumberOfComments((prev) => prev - 1)
                    } else if (comment.typing) {
                        // console.log(comment.user)
                        const { typing, uuid } = JSON.parse(message.body);

                        setOtherUsersTyping((prev) => {
                            const updated = new Set(prev);
                            if (typing && user && (uuid != user.uuid)) {
                                updated.add(uuid);
                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else if (!user && typing) {
                                updated.add(uuid);
                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            }
                            return updated;
                        });
                    } else if (!comment.typing) {
                        const { typing, uuid } = JSON.parse(message.body);

                        setOtherUsersTyping((prev) => {
                            const updated = new Set(prev);
                            if (!typing && user && (uuid != user.uuid)) {
                                updated.delete(uuid);
                            } else if (!user && !typing) {
                                updated.delete(uuid);
                            }
                            return updated;
                        });
                    }
                } catch (e) {
                    // console.log("Not a comment")
                }
            } else {
                // alert('got empty message');
            }
        });
        setSubscription(sub)
        // client.publish({ destination: '/app/' + urlparam + "/" + thePost?.duid, body: 'First Message Hola' });
        // client.publish({ destination: '/topic/' + urlparam + "/" + thePost?.duid, body: 'First Message' });

    }

    client.activate();

    const sendMessage = (e) => {
        e.preventDefault();
        client.send("/app/servicespace/sendMessage", {}, JSON.stringify({ content: 'message' }));
    };

    useEffect(() => {
        setActualEditButton(actualEditButton)
    }, [actualEditButton])

    useEffect(() => {
        $('html, body').animate({
            scrollTop: $("#" + commentViewid + "open").offset().top - 40
        }, 100);

        setTimeout(1000)

        var comment = new CommentClass();

        comment.text = "";
        comment.discussion = thePost.duid
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

        dispatch(commentActions.getComments(commentInfos));
        $('#' + commentViewid).animate({ scrollTop: 99999999999 }, 3000);
    }, [])

    useEffect(() => {
        // Retrieve all comments
        if (entry) {
            if (d) {
                setCommentList(c)
                setEntry(false)
                setLoadingMessage(false)
            }
        }
    }, [d])

    // useEffect(() => {
    //     // Adding comment
    //     if (addingComment) {
    //         setCommentList(commentList)
    //         setAddingComment(false)
    //         console.log("gggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg")
    //     }
    // }, [addingComment])

    const sendStopTyping = () => {
        if (authenticated) {
            if (client && client.connected) {
                client.publish({ destination: '/topic/' + urlparam + "/" + thePost?.duid, body: JSON.stringify({ typing: false, uuid: user.uuid }) });
            }
        }
    };

    const startTyping = () => {
        if (authenticated) {
            if (client && client.connected) {
                // Reset the timeout every time the user types
                clearTimeout(typingTimeoutId.current);

                // Send "typing" status over WebSocket with the user's UUID
                client.publish({ destination: '/topic/' + urlparam + "/" + thePost?.duid, body: JSON.stringify({ typing: true, uuid: user.uuid }) });

                // Set a timeout to send "stop typing" after a period of inactivity
                typingTimeoutId.current = setTimeout(sendStopTyping, 5000);
            }
        }
    };

    useEffect(() => {
        // Clean up the timeout on component unmount
        return () => clearTimeout(typingTimeoutId.current);
    }, []);

    function isScrollAtBottom() {
        const messagesContainer = $('#' + commentViewid);
        const scrollPosition = messagesContainer.scrollTop() + messagesContainer.innerHeight();
        const totalHeight = messagesContainer[0].scrollHeight;

        // Check if the scroll is at the bottom (with a small threshold)
        return scrollPosition + 5 >= totalHeight;
    }

    function scrollToBottom() {
        // const messagesContainer = $('#' + commentViewid);
        $('#' + commentViewid).animate({ scrollTop: 99999999999 }, 500);
    }

    const isSomeoneTyping = otherUsersTyping.size > 0;

    const spacepath = "/space/" + urlparam;

    const openOneSpace = (e) => {
        e.preventDefault()
        navigate(spacepath)
        resetPage()
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    return (
        <Container>
            <div style={{ display: 'block' }} id={commentViewid + "open"}>
                <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                    <a className="white" style={{ color: "#fff", textDecoration: "none", cursor: "pointer" }} onClick={openOneSpace}>{space}</a>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.5rem' }}>
                        {/* <EditIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={e => setLayoutPreview(2)} /> */}
                        <InfoIcon className="notDraggable text-white" onClick={handleOpenInfoPopup} style={{ cursor: "pointer" }} />
                        {/* <DeleteIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={e => handleOpenDeletePopup()} /> */}
                    </div>
                </h6>

                <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"comment"} />

                <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />
                <Card sx={styleMobLarge2}>
                    <div className={classes.container}>
                        <Paperx className={classes.paper} zDepth={2}>
                            {loadingMessage ?
                                <Paperx ref={commentRef} id={commentViewid} className={classes.messagesBody}>
                                    <SpinnerLoading show={loadingMessage} theme={theme} />
                                </Paperx>
                                :
                                <Paperx ref={commentRef} id={commentViewid} className={classes.messagesBody}>
                                    {commentList?.length == 0 ?
                                        <>
                                            <BeginText show={true} open={open} text={"Begin to Comment"} theme={theme} />
                                        </>
                                        :
                                        <>
                                            {commentList && commentList.map((d, idx) => (
                                                <>
                                                    {(user?.uuid != d.createdby?.uuid) &&
                                                        <MessageLeft
                                                            key={idx}
                                                            authenticated={authenticated}
                                                            reported={d.reported}
                                                            cuid={d?.cuid}
                                                            duid={duid}
                                                            uuid={d?.createdby?.uuid}
                                                            user={user}
                                                            message={d?.text}
                                                            timestamp={d?.timeago}
                                                            photoURL={d?.createdby?.pictureUrl}
                                                            displayName={d.createdby.displayname ? d.displayname : truncateFullName(d?.createdby?.firstname + " " + d?.createdby?.name)}
                                                            avatarDisp={true}
                                                            createdby={d?.createdby}
                                                            setBalanceOnStar={setBalanceOnStar}
                                                            starStyle={starStyle}
                                                            balance={d?.balance}
                                                            lumens={d?.lumens}
                                                            commentid={"message" + idx}
                                                            chat={false}
                                                            edited={d?.edited}
                                                        />}
                                                    {(user?.uuid == d.createdby?.uuid) &&
                                                        <MessageRight
                                                            key={idx}
                                                            comment={d}
                                                            authenticated={authenticated}
                                                            cuid={d?.cuid}
                                                            duid={duid}
                                                            uuid={d?.createdby?.uuid}
                                                            user={user}
                                                            message={d?.text}
                                                            timestamp={d?.timeago}
                                                            photoURL={d?.createdby?.pictureUrl}
                                                            displayName={d.createdby.displayname ? d.displayname : truncateFullName(d?.createdby?.firstname + " " + d?.createdby?.name)}
                                                            avatarDisp={true}
                                                            createdby={d?.createdby}
                                                            balance={d?.balance}
                                                            lumens={d?.lumens}
                                                            commentid={"message" + idx}
                                                            chat={false}
                                                            thePost={thePost}
                                                            setOpenEditmode={setOpenEditmode}
                                                            setCommentForEdit={setCommentForEdit}
                                                            editbuttonid={"editbutton" + idx}
                                                            edited={d?.edited}
                                                        />}
                                                </>
                                            ))}
                                        </>
                                    }


                                    {isSomeoneTyping ? <MessageBlank
                                        message={""}
                                        timestamp={""}
                                        photoURL={""}
                                        displayName={""}
                                        avatarDisp={true}
                                    /> : null}
                                </Paperx>}

                            <TextInput
                                authenticated={authenticated}
                                setLayoutPreview={setLayoutPreview}
                                thePost={thePost}
                                urlparam={urlparam}
                                resetButtonid={resetButtonid}
                                commentList={commentList}
                                handleOpenSignInPopup={handleOpenSignInPopup}
                                client={client}
                                commentViewid={commentViewid}
                                startTyping={startTyping}
                                isScrollAtBottom={isScrollAtBottom}
                                scrollToBottom={scrollToBottom}
                                sendStopTyping={sendStopTyping}
                                subscription={subscription}
                                setBalanceOnLumen={setBalanceOnLumen}
                                setBalanceOnNun={setBalanceOnNun}
                                lumenStyle={lumenStyle}
                                nunStyle={nunStyle}
                                lumens={lumens}
                                numcomments={numcomments}
                                open={open}
                                setOpen={setOpen}
                                openEditmode={openEditmode}
                                setOpenEditmode={setOpenEditmode}
                                commentForEdit={commentForEdit}
                                numberOfComments={numberOfComments}
                                setNumberOfComments={setNumberOfComments}
                            />
                        </Paperx>
                    </div>
                </Card>
            </div>
        </Container>
    );
}


export const TextInput = ({
    setLayoutPreview,
    thePost,
    urlparam,
    resetButtonid,
    commentList,
    handleOpenSignInPopup,
    authenticated,
    open,
    setOpen,
    startTyping,
    sendStopTyping,
    isScrollAtBottom,
    scrollToBottom,
    subscription,
    setBalanceOnLumen,
    setBalanceOnNun,
    lumenStyle,
    nunStyle,
    lumens,
    numcomments, // static
    openEditmode,
    setOpenEditmode,
    commentForEdit,
    numberOfComments,
    setNumberOfComments
}) => {


    const dispatch = useDispatch();
    const comment = useSelector(state => state.comment.comment);
    const e = useSelector(state => state.comment.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.comment.done);
    const [done, setDone] = useState(d);
    const [commenting, setCommenting] = useState(false)
    const [wsActive, setWsActive] = useState(client.active)

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const [styleComment, setStyleComment] = useState({ color: '38E54D' })

    const valueRef = useRef('')

    useEffect(() => {
        setTheme(t);
    }, [t])

    const [inputText, setInputText] = useState("");

    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (commenting) {
            // if (wsActive) {
            if (d) {
                if (e !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    setCommenting(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    setCommenting(false)
                    //setInputText("")
                    valueRef.current.value = ""
                    // commentList.comments.push(comment)                    
                }
            }
            // }
        }
    }, [d]);

    const useDebounce = (initialValue = "", delay) => {
        const [actualValue, setActualValue] = useState(initialValue);
        const [debounceValue, setDebounceValue] = useState(initialValue);
        useEffect(() => {
            const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
            return () => clearTimeout(debounceId);
        }, [actualValue, delay]);
        return [debounceValue, setActualValue];
    };

    const [value, setValue] = useDebounce('', 1000)

    useEffect(() => {
        if (openEditmode) {
            if (commentForEdit) {
                valueRef.current.value = commentForEdit?.text
            }
        } else {

        }
    }, [openEditmode, commentForEdit])


    useEffect(() => {
        if (open) {
            valueRef.current.value = ""
        } else {

        }
    }, [open])

    // useEffect(() => {
    //     setWsActive(client.active)
    // }, [wsActive]);

    const AddComment = (e) => {
        if (authenticated) {
            var input = valueRef.current.value;

            if (!isOnlyWhitespace(input)) {
                var comment = new CommentClass();

                comment.text = input;
                comment.discussion = thePost?.duid
                comment.adding = true
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

                setCommenting(true)
                dispatch(commentActions.writeComment(commentInfos));
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const EditComment = (e) => {
        if (authenticated) {
            if (commentForEdit && commentForEdit.cuid) {
                var input = valueRef.current.value;

                if (!isOnlyWhitespace(input)) {
                    var comment = new CommentClass();

                    comment.text = input;
                    comment.cuid = commentForEdit?.cuid
                    comment.discussion = thePost?.duid
                    comment.editing = true

                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

                    setCommenting(true)

                    // $('#' + commentid).remove();
                    dispatch(commentActions.writeComment(commentInfos));
                }
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const handleChange = e => {
        if (e.target.id === "comment") {
            setInputText(e.target.value);
        }
    };

    const handleChangeWithDelay = e => {
        if (e.target.id === "comment") {
            setValue(e.target.value)
        }
    };

    const handleNewComment = () => {
        setOpen((prev) => !prev);
        if (isScrollAtBottom()) {
            scrollToBottom();
        }
    };

    const handleCloseEdit = () => {
        setOpenEditmode(false);
    }

    const handleTyping = () => {
        const typing = { text: 'typing' }
        const body = JSON.stringify(typing)
        client.publish({ destination: '/topic/' + urlparam + "/" + thePost?.duid, body: body });
    };

    const closeComment = () => {
        if (authenticated) {
            sendStopTyping();
        }
        if (subscription) {
            subscription.unsubscribe();
        }
        if (client && client.connected) {
            client.deactivate();
        }

        setLayoutPreview(1)
    }

    return (
        <Container style={{ paddingRight: 0, paddingLeft: 0, width: '100%', color: '#fff' }}>
            <Zoom in={open || openEditmode} style={{ display: open || openEditmode ? 'block' : 'none', transitionDelay: open || openEditmode ? '500ms' : '0ms' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    p={2}
                >
                    {/* <TextField
                    id="standard-text"
                    label="Add New Comment"
                    className={classes.wrapText}
                    multiline
                    rows={1}
                    maxRows={Infinity}
                //margin="normal"
                /> */}
                    <Item style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            id="comment"
                            multiline
                            rows={5}
                            placeholder={openEditmode ? "Edit a comment" : "Add a comment..."}
                            sx={styleTextField}
                            InputProps={{
                                style: { color: theme === 'dark' ? '#fff' : '#000' }, // light
                            }}
                            inputRef={valueRef}
                            defaultValue={openEditmode ? commentForEdit?.text : ""}
                            // value={inputText}
                            // onChange={handleChange}
                            onKeyUp={startTyping}
                        // error={inputText.length > characterLimit}
                        // helperText={inputText.length > characterLimit ? "" : ""}
                        /></Item>
                    <Item>
                        <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={openEditmode ? EditComment : AddComment}>
                            <SendIcon />
                        </Button></Item>
                </Stack>
            </Zoom>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                p={1}
            >
                <Item>
                    <div style={{ background: "#1976d2", borderRadius: 30, width: 120, display: 'inline-block', marginBottom: 15 }} disabled={openEditmode}>
                        <IconButton onClick={openEditmode && !open ? handleCloseEdit : handleNewComment} style={{ /*marginTop: '-5px',*/ background: "#1976d2", color: "#fff", transform: open ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}>
                            {openEditmode && !open ? <CancelIcon fontSize="large" /> : <ArrowCircleUpIcon fontSize="large" />}
                        </IconButton>
                        <span onClick={openEditmode && !open ? handleCloseEdit : handleNewComment} style={{ cursor: "pointer", fontSize: '1.2em', paddingRight: 15, textTransform: 'uppercase', fontWeight: 'bold' }}>{openEditmode && !open ? "Edit" : "New"}</span>
                    </div>
                </Item>
            </Stack>
            {!open && !openEditmode ? <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                p={1}
            >
                <Item>
                    <Button id={resetButtonid} size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={closeComment}>
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(numberOfComments)}</span><CommentIcon style={styleComment} /></Box>
                    </Button>
                    {/* <Button size="small" variant="contained" style={{ borderRadius: 20, padding: 5, fontWeight: 'bold', marginLeft: 8 }}>Comment</Button> */}
                </Item>
                <Item style={{ paddingLeft: 0 }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                    >
                        <Item style={{ paddingLeft: 0 }}>
                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnLumen}>
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(lumens)}</span><TipsAndUpdatesIcon style={lumenStyle} /></Box>
                                {/* <TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /> */}
                            </Button>
                        </Item>
                        <Item style={{ paddingLeft: 0 }}>
                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnNun}>
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><TipsAndUpdatesIcon style={nunStyle} /></Box>
                                {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                            </Button>
                        </Item>
                    </Stack>
                </Item>
            </Stack> : null}
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    paper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 350,
        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 500
        },

        '@media (min-width: 800px)': {
            width: 600
        }
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    },
}));

const useProfileStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: 'transparent !important',
        overflow: 'hidden !important',
        width: 300,
        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 300
        },

        '@media (min-width: 800px)': {
            width: 300
        }
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const DraggableDeletePopup = ({ openDeletePopup, handleCloseDeletePopup, handleSubmit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openDeletePopup}
                onClose={handleCloseDeletePopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>Deleting</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}>Are you sure you want to delete ?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseDeletePopup} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableSignInPopup = ({ openSignInPopup, handleCloseSignInPopup, handleSubmit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const lop = useSelector(state => state.listonespaceswitch);
    const ss = useSelector(state => state.spaceswitch);
    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))

    const ds = useSelector(state => state.discussion.discussionList);

    const menuSwitch = useSelector(state => state.menuswitch);

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    const handleLoginSuccess = (response) => {
        const idToken = response.credential;
        // Send idToken to your backend via HTTPS
        // Backend should verify token and respond with the user's role
        // This is a simplified example; you would typically use Axios or Fetch API to make the HTTP request.
        // console.log(idToken)
        dispatch(request({ user: "" }));
        fetch(COMMUNO + "api/auth/google", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken: idToken }),
        })
            .then(response => response.json())
            .then(data => {
                dispatch(success(data))
                // console.log(data);
                sessionStorage.setItem('user', JSON.stringify(data));

                resetPage()

                handleCloseSignInPopup();

                if (menuSwitch == switchConstants.PUBLISHERS_NR) {
                    dispatch(publisherActions.getPublisherList())
                }

                if (menuSwitch == switchConstants.SPACE_NR) {
                    if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                        if (ss == spaceswitchConstants.FEED_NR) {
                            dispatch(discussionActions.getFeedFilter())
                            dispatch(discussionActions.getFeed());
                        }
                        if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                            dispatch(spaceActions.getSpaceList());
                        }
                        if (ss == spaceswitchConstants.MY_SPACE_NR) {
                            dispatch(discussionActions.getMyDiscussionList());
                        }
                        if (ss == spaceswitchConstants.LIGHT_NR) {
                            dispatch(discussionActions.getLightDiscussionList());
                        }
                        if (ss == spaceswitchConstants.FIND_NR) {
                            dispatch(profilActions.getProfilList());
                        }
                    }

                    if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                        if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                            if (ds && ds.urlparam) {
                                localStorage.setItem("urlparam", ds.urlparam)
                                dispatch(discussionActions.getDiscussionList(localStorage.getItem("urlparam"), "", false));
                            } else {
                                dispatch(discussionActions.getDiscussionList("guideandannouncement"));
                            }
                        }
                        if (ss == spaceswitchConstants.MY_SPACE_NR) {
                            dispatch(discussionActions.getMyDiscussionList(urlp, "", false));
                        }
                        if (ss == spaceswitchConstants.LIGHT_NR) {
                            dispatch(discussionActions.getLightDiscussionList());
                        }
                        if (ss == spaceswitchConstants.FIND_NR) {
                            dispatch(profilActions.getProfilList());
                        }
                    }
                }

                dispatch(getAllInfosForSpace())

                console.log('You are logged in, ' + data.firstname);
                Toast.fire({
                    icon: "success",
                    title: "Welcome " + data.firstname + " !"
                });

                handleCloseSignInPopup();
            })
            .catch(error => {
                console.error('Error:', error);
                Toast.fire({
                    icon: "error",
                    title: "An error has occurred. Please try again ..."
                });

                handleCloseSignInPopup();
            });

        function request(payload) { return { type: userConstants.LOGIN_REQUEST, payload } }
        function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
    };

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    const handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
        dispatch(failure(getErrorMessageFetch(error)))
        dispatch(error(getErrorMessageFetch(error)))

        function failure(payload) { return { type: userConstants.LOGIN_FAILURE, payload } }
        function error(payload) { return { type: alertConstants.ERROR, payload } }
    };

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var textSpace = "To share, simply log in first.It's a step away to joining the Space!"
    var textPublisher = "To pin, simply log in first.It's a step away to joining the Space!"
    var text = textSpace;

    if (menuSwitch == switchConstants.PUBLISHERS_NR) {
        text = textPublisher
    }

    return (
        <div>
            <Dialog
                open={openSignInPopup}
                onClose={handleCloseSignInPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>SignIn</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}>{text}</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseSignInPopup} variant="contained">
                        Cancel
                    </Button>
                    <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginFailure}
                        text={"continue_with"}
                        theme={"filled_blue"}
                    // useOneTap
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableWarningProfilPopup = ({ openWarningProfilPopup, handleCloseWarningProfilPopup }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openWarningProfilPopup}
                onClose={handleCloseWarningProfilPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>Sharing</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}>Please save your profile with a username to start sharing it with others!</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseWarningProfilPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableLoadingPopup = ({ openLoadingPopup, handleCloseLoadingPopup }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openLoadingPopup}
                onClose={handleCloseLoadingPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>Loading</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, textAlign: 'center' }}>Please wait while everything loads completely, so you can enjoy all the functionalities at their best!</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseLoadingPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableShowProfilePopup = ({ openShowProfilePopup, handleCloseShowProfilePopup, createdby, balance, setBalanceOnStar, starStyle, showStar, profile }) => {
    const classes = useProfileStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const [shareStyle, setShareStyle] = useState({ color: "#fff", transform: 'scaleX(-1)' })

    useEffect(() => {
        setTheme(t);
    }, [t])

    var displaynameAgeGender = createdby.displayname ?
        truncateFullName(createdby.displayname) + (createdby.showage && createdby.age > 1 ? (", " + createdby.age) : "") + ((!createdby.showgender || !createdby.gender || createdby.gender == "NONE") ? "" : (", " + shortGender(createdby.gender)))
        : (createdby.name ?
            truncateFullName(createdby.firstname + " " + createdby.name) + (createdby.showage && createdby.age > 1 ? (", " + createdby.age) : "") + ((!createdby.showgender || !createdby.gender || createdby.gender == "NONE") ? "" : (", " + shortGender(createdby.gender)))
            :
            truncateFullName(createdby.firstname) + (createdby.showage && createdby.age > 1 ? (", " + createdby.age) : "") + ((!createdby.showgender || !createdby.gender || createdby.gender == "NONE") ? "" : (", " + shortGender(createdby.gender))))

    var title = createdby.displayname ?
        createdby.displayname + " - OneLike Social"
        : (createdby.name ?
            createdby.firstname + " " + createdby.name + " - OneLike Social"
            :
            createdby.firstname + " - OneLike Social")

    return (
        <div>
            <Dialog
                open={openShowProfilePopup}
                onClose={handleCloseShowProfilePopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogContent style={{ padding: 0, overflow: 'hidden', backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', borderRadius: 20 }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Item style={{ padding: 20 }}>
                                {createdby.atname && createdby.visibility && createdby.visibility.includes("VISIBLE") && createdby.access && createdby.access.includes("PUBLIC") ?
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={0}
                                    >
                                        <Share shareStyle={shareStyle} title={title} post={createdby.description ? createdby.description : ""} path={createdby.atname} preUrl={preUrlProfil} profilsharing={true} />
                                    </Stack>
                                    : null}
                            </Item>
                            {showStar ?
                                <Item style={{ padding: 20 }}>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={0}
                                        p={0}
                                    >
                                        <Item>
                                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnStar}>
                                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><StarIcon style={starStyle} /></Box>
                                            </Button>
                                        </Item>
                                        {/* {balance?.star && createdby.synced ? <Item>
                                            <Button size="small" variant="contained" style={{ cursor: "auto", backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} title={"Synced"}>
                                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><SyncIcon style={starStyle} /></Box>
                                            </Button>
                                        </Item> : null} */}
                                    </Stack>
                                </Item> :
                                <Item><span></span></Item>
                            }
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            p={3}
                        ><Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0}
                            style={{ display: 'flex' }}
                        >
                                <Item style={{ padding: 0, cursor: 'auto' }} /*onClick={() => handleOpenProfilPopup()}*/>
                                    {profile ?
                                        <div style={{ backgroundColor: '#166fe5', padding: 10, borderRadius: 20 }}>
                                            <img
                                                width="250"
                                                srcSet={createdby.previewUrl ?
                                                    createdby.previewUrl
                                                    : createdby.profileUrl ?
                                                        createdby.profileUrl : "/avatar3.png"}
                                                src={createdby.previewUrl ?
                                                    createdby.previewUrl
                                                    : createdby.profileUrl ?
                                                        createdby.profileUrl : "/avatar3.png"}
                                                alt={"preview"}
                                                loading="lazy"
                                            />
                                        </div>
                                        :
                                        <div style={{ backgroundColor: '#166fe5', padding: 10, borderRadius: 20 }}>
                                            <img
                                                width="250"
                                                srcSet={createdby.previewUrl ?
                                                    createdby.previewUrl
                                                    : createdby.pictureUrl ?
                                                        createdby.pictureUrl : "/avatar3.png"}
                                                src={createdby.previewUrl ?
                                                    createdby.previewUrl
                                                    : createdby.pictureUrl ?
                                                        createdby.pictureUrl : "/avatar3.png"}
                                                alt={"preview"}
                                                loading="lazy"
                                            />
                                        </div>}
                                </Item>
                                <Item style={{ cursor: 'auto', color: theme == 'dark' ? "#fff" : "#000" }} /*onClick={() => handleOpenProfilPopup()}*/>{displaynameAgeGender}</Item>
                                <Item style={{ cursor: 'auto', color: theme == 'dark' ? "#fff" : "#000" }} /*onClick={() => handleOpenProfilPopup()}*/>{createdby.atname ? "@" + truncateFullName(createdby.atname) : ""}</Item>
                            </Stack>
                            <p style={{ marginBottom: 16 }}>
                                {!isOnlyWhitespace(createdby.mytwitterx) ? <><a class="hover" target="_blank" href={createdby.mytwitterx}><XIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.myreddit) ? <><a target="_blank" href={createdby.myreddit}><RedditIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.mytelegram) ? <><a target="_blank" href={createdby.mytelegram}><TelegramIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.mylinkedin) ? <><a target="_blank" href={createdby.mylinkedin}><LinkedInIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.mygithub) ? <><a target="_blank" href={createdby.mygithub}><GitHubIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.myyoutube) ? <><a target="_blank" href={createdby.myyoutube}><YouTubeIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.mytwitch) ? <><a target="_blank" href={createdby.mytwitch}><TwitchBlueIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.myinstagram) ? <><a target="_blank" href={createdby.myinstagram}><InstagramIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.myfacebook) ? <><a target="_blank" href={createdby.myfacebook}><FacebookIcon style={{ marginBottom: 3 }} /></a>{" "}</> : null}
                                {!isOnlyWhitespace(createdby.mytiktok) ? <><a target="_blank" href={createdby.mytiktok}><TikTokIcon style={{ marginBottom: 3 }} /></a></> : null}
                            </p>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                p={0}
                                style={{ display: "flex", marginTop: 5 }}
                            >
                                <Item style={{ paddingLeft: 0 }}>
                                    <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{createdby.stars}</span><StarIcon style={{ color: "#FFFF00" }} /></Box>
                                </Item>
                                <Item><Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{createdby.lumens}</span><TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /></Box></Item>
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export const DraggableInfoPopup = ({ openInfoPopup, handleCloseInfoPopup, info, publishers }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const i = useSelector(state => publishers ? state.infonewsguides?.infoList?.guides : state.info?.infoList?.infos);
    const [infos, setInfos] = useState(i);

    // const [infoItem, setInfoItem] = useState(null);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setInfos(i);
    }, [i])

    var selectInfo = infos?.map(item => {
        if (item.title === info) {
            return item;
        }
        return "";
    });

    return (
        <div>
            <Dialog
                open={!!selectInfo && openInfoPopup}
                onClose={handleCloseInfoPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                {infos && infos?.map(item => {
                    if (item.title === info) {
                        return (
                            <>
                                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                                    <span>Info</span>
                                </DialogTitle>
                                <DialogContent style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', padding: 0, overflowX: 'hidden', overflowY: 'auto' }}>
                                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, /*textAlign: 'center',*/ whiteSpace: 'pre-line', padding: 10 }}>{item.text}</p>
                                    </DialogContentText>
                                </DialogContent>
                            </>
                        );
                    }
                })}

                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseInfoPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableOutOfScopePopup = ({ openOutOfScopePopup, handleCloseOutOfScopePopup, handleSubmit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])


    return (
        <div>
            <Dialog
                open={openOutOfScopePopup}
                onClose={handleCloseOutOfScopePopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
            >

                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>Moving to "Out of Scope"</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <p style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, /*textAlign: 'center',*/ whiteSpace: 'pre-line', padding: 10 }}>
                            Would you like to move this Content to "Out Of Scope" ?
                        </p>
                    </DialogContentText>
                </DialogContent>

                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseOutOfScopePopup} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const ImageBundle = ({ files, setFiles, postpreview2 }) => {

    const linkspreview = ['/images/1.jpg', '/images/2.jpg', '/images/3.jpg', '/images/4.jpg', '/images/5.jpg', '/images/6.jpg',]
    var autoplayTime = 5000;
    var name = "";
    var theme = "";
    const [entry, setEntry] = useState(true)

    // console.log("ImageBundle1 :" + files)

    useEffect(() => {
        // console.log("ImageBundle2 :" + files)
        if (postpreview2) {
            if (entry) {
                // console.log("ImageBundle3 :" + files)
                setFiles((prev) => {
                    const imgs = prev;
                    var imgsNew = [];
                    imgs.map((item, idx) => {
                        var img = new ImageClass()
                        img.uiid = item.uiid
                        img.url = item.url
                        imgsNew.push(item)
                    })
                    return imgsNew;
                });
                setEntry(false)
            }
        }
    }, [])

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    '--swiper-navigation-size': '150px'
                }}
                centeredSlides={true}
                loop={true}
                autoHeight={true}
                effect={"fade"}
                autoplay={{
                    delay: autoplayTime,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                lazy={true}
                navigation={files && files.length !== 0 ? true : false}
                modules={[Autoplay, Pagination, Navigation, EffectFade]}
                className="imgSwiper"
            >
                {files && files.length !== 0 ?
                    files.map((item, idx) => {
                        var pathPreview;
                        if (item.new) {
                            pathPreview = window.URL.createObjectURL(item.file)
                        }

                        const PreviewImage = () => {
                            const [loaded, setLoaded] = useState(false);
                            const showImage = () => {
                                setLoaded(true)
                            }
                            const [error, setError] = useState(false);
                            const hasError = () => {
                                setError(true)
                            }

                            return (
                                <div key={idx}>
                                    <Suspense>
                                        <SuspenseImg
                                            alt=""
                                            src={error ? "/onelike_1200x620.png" : item.new ? pathPreview : item.url}
                                            onError={(e) => { e.preventDefault(); hasError(); }}
                                        />
                                    </Suspense>
                                </div>
                            );
                        }

                        return (
                            <SwiperSlide>
                                <div>
                                    <PreviewImage />

                                </div>
                            </SwiperSlide>);
                    }) : null}
            </Swiper>
        </>
    );
}

const ImageBundleExample = ({ files }) => {

    const linkspreview = ['https://imagedelivery.net/FlqLxguSo_9c3dI_ZYSkWQ/485edbbd-c86d-43c8-0dd6-c9496e95d900/public']
    var autoplayTime = 5000;
    var name = "";
    var theme = "";

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    '--swiper-navigation-size': '150px'
                }}
                centeredSlides={true}
                loop={true}
                autoHeight={true}
                effect={"fade"}
                autoplay={{
                    delay: autoplayTime,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                lazy={true}
                navigation={linkspreview && linkspreview.length !== 0 ? true : false}
                modules={[Autoplay, Pagination, Navigation, EffectFade]}
                className="imgSwiper"
            >
                {linkspreview && linkspreview.length !== 0 ?
                    linkspreview.map((item, idx) => {
                        // const pathPreview = window.URL.createObjectURL(item.file)

                        const PreviewImage = () => {
                            return (
                                <div key={idx}>
                                    <img alt="" src={item} loading="lazy" />
                                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                </div>
                            );
                        }

                        return (
                            <SwiperSlide>
                                <div>
                                    <PreviewImage />

                                </div>
                            </SwiperSlide>);
                    }) : null}
            </Swiper>
        </>
    );
}


export const DefaultImage = (socialmedia, theme) => {
    var defaultImage;
    var windowSize = window.innerWidth;
    if (windowSize <= 320) {
        defaultImage = "/" + "x-twitter" + "-image" + "-" + "dark" + "320" + ".png";
    } else {
        defaultImage = "/" + "x-twitter" + "-image" + "-" + "dark" + ".png";
    }
    return defaultImage;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export function InputFileUpload({ files, setFiles, uploadLimit, inputUpload }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    const handleFileUpload = (e) => {
        // console.log(e.target.files)
        if (e.target.files.length > 0) {
            let ar = [...files];
            for (var i = 0; i < e.target.files.length; i++) {
                var img = new ImageClass()
                img.file = e.target.files[i]
                img.uiid = img.file.name
                img.new = true
                ar.push(img);
            }
            setFiles(ar);
        }
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                p={0}
            >
                <Item style={{ padding: 0 }}><Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    style={{ backgroundColor: "#166fe5", borderRadius: 20, width: 20, padding: 5 }}
                    startIcon={<AddPhotoAlternateIcon style={{ marginLeft: 10 }} />}
                >
                    <VisuallyHiddenInput id={inputUpload} type="file" accept="image/x-png, image/gif, image/jpeg, .webp" multiple onChange={handleFileUpload} />
                </Button></Item>
                <Item>{files.length > 0 ? <span style={{ color: theme === 'dark' ? '#fff' : '#000', float: "right" }}>{files.length}/{uploadLimit}</span> : null}</Item>
            </Stack>
            {files.length > uploadLimit ? (<FormHelperText error={true}>Limit exceed</FormHelperText>) : null}
        </>
    );
}

const FileUploadList = ({ files, setFiles, inputUpload, editImageInfos, setEditImageInfos, editMode, uploadPreviewLimit }) => {

    const deleteFile = (fileName) => {
        // console.log(fileName)
        if (files.length == 1) {
            $('#' + inputUpload).val("");
        }

        if (editMode) {
            if (files && files.length != 0) {
                for (var i = 0; i < files.length; i++) {
                    if (files[i].uiid == fileName && !files[i].new) {
                        var editInfo = new OneEditInfoClass()
                        editInfo.uiid = files[i].uiid
                        editInfo.deleted = true
                        setEditImageInfos((prev) => {
                            const edf = prev;
                            prev.push(editInfo)
                            return edf;
                        })
                    }
                }
            }
        }

        // console.log(editImageInfos);

        setFiles(files.filter((item) => {
            return item.uiid != fileName;
        }));
    }

    return (
        <ImageList sx={{ maxWidth: '80vw', overflowY: 'auto', marginTop: '15px' }} cols={uploadPreviewLimit}>
            {files ? files.map((item, index) => {
                var pathPreview;
                if (item.new) {
                    pathPreview = window.URL.createObjectURL(item.file)
                }

                return (
                    <ImageListItem key={index} style={{ marginBottom: '15px' }}>
                        <div style={{ backgroundColor: '#166fe5', padding: 10, borderRadius: 20 }}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={1}
                            >
                                <Item><img
                                    width="100"
                                    height="100"
                                    srcSet={item.new ? pathPreview : item.url}
                                    src={item.new ? pathPreview : item.url}
                                    alt={item.uiid}
                                    loading="lazy"
                                /></Item>
                                <Item>
                                    <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={() => { deleteFile(item.uiid) }}>
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><DeleteIcon /></Box>
                                    </Button>
                                </Item>
                            </Stack>
                        </div>
                    </ImageListItem>
                )
            }) : ""}
        </ImageList>
    );

}

const SpaceSelect = ({ user, spaceList, post, setPost, setSpaceNameForFeed, spaceid, editMode }) => {

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setPost(post)
    }, [post])

    useEffect(() => {
        setTheme(t);
    }, [t])

    const handleChange = e => {
        if (e.target.name === "space") {
            // console.log(e.target.value)
            setPost({ ...post, ["spaceid"]: e.target.value });
            setSpaceNameForFeed(spaceList?.spaces[e.target.value - 1].name)
        }
    };

    useEffect(() => {
        if (editMode) {
            // console.log("spaceid : " + spaceid)
            // console.log(spaceList?.spaces)
            setPost({ ...post, ['spaceid']: spaceid });
            setSpaceNameForFeed(spaceList?.spaces[spaceid - 1].name)
        }
    }, [])

    return (
        <FormControl fullWidth>
            <Select
                id="space"
                labelId="space-label"
                defaultValue={spaceid ? spaceid : ""}
                value={post && post.spaceid ? post.spaceid : ""}
                onChange={handleChange}
                // onChange={handleChange}
                inputProps={{
                    name: "space",
                    id: "space",
                    'aria-label': 'Without label'
                }}
                fullWidth
                // focused // dark mode
                sx={{
                    color: theme == 'dark' ? '#fff' : '#000',
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#007bff',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#007bff',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#007bff',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: theme == 'dark' ? '#fff !important' : '#007aff !important',
                    },
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                style={{ marginTop: "20px" }}
                label="Space"
                displayEmpty
            >
                <MenuItem disabled value="">
                    <em>Space</em>
                </MenuItem>
                {spaceList && spaceList.spaces && spaceList.spaces.map((item, idx) => (
                    <MenuItem
                        key={idx}
                        value={item.id}
                    >
                        {item.name == "Guide and Announcement" ?
                            <>
                                {item.name} {(user && user.role && user.role.includes("MODERATOR")) || (user && user.role && user.role.includes("ADMIN")) ? "" : <LockSharpIcon />}
                            </>
                            :
                            <>
                                {item.name}
                            </>
                        }
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
